import React from 'react'

const DefaultArrows = () => (
  <svg
    width='57px'
    height='26px'
    viewBox='0 0 57 26'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g id='Page-1' stroke='none' strokeWidth='1' fillRule='evenodd'>
      <path
        d='M2.52623381,0.000503634429 C3.06342515,0.0124412198 3.57321807,0.240031477 3.94075138,0.63199616 L13.8403351,11.1896438 C14.5605046,11.9576858 14.5618974,13.1525477 13.8435203,13.9222666 L3.46213708,25.0455976 C2.70848437,25.8531132 1.44290744,25.8967777 0.635391877,25.143125 C0.218794978,24.7543159 -0.0121656886,24.2062615 0.000494449937,23.6365552 L0.482293977,1.95557652 C0.506833907,0.851279648 1.42193694,-0.024036296 2.52623381,0.000503634429 Z M22.9998212,0.28884303 C23.5266221,0.288795926 24.0321683,0.496596475 24.4066535,0.867109654 L35.2021415,11.5480938 C35.9873432,12.3249671 35.9940947,13.5912791 35.2172214,14.3764807 C35.212305,14.3814498 35.2073626,14.3863931 35.2023944,14.3913103 L24.4088167,25.0742047 C23.6237533,25.8512177 22.3574401,25.8446915 21.5804272,25.0596281 C21.2098478,24.6852092 21.0019574,24.1797007 21.0019103,23.6529005 L21,2.28902186 C20.9999012,1.18445236 21.8952517,0.288941797 22.9998212,0.28884303 Z M44,0.288042933 C44.5267685,0.288042933 45.0322678,0.495863201 45.4067142,0.866369579 L56.201834,11.5479009 C56.9870026,12.3248077 56.9937,13.59112 56.2167932,14.3762885 L56.201834,14.3912476 L56.201834,14.3912476 L45.4067142,25.072779 C44.6215457,25.8496858 43.3552335,25.8429883 42.5783266,25.0578198 C42.2078203,24.6833735 42,24.1778741 42,23.6511056 L42,2.28804293 C42,1.18347343 42.8954305,0.288042933 44,0.288042933 Z'
        id='1'
      />
    </g>
  </svg>
)

export default DefaultArrows
