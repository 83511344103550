import { getFontStyles } from '@elo-kit/utils/fonts.utils'
import { getBackgroundImageCss } from '@elo-kit/utils/themes.utils'
import { BUTTON_STYLE } from '@elo-kit/constants/contentPage.constants'
import { COLORS } from '@elo-kit/constants/general.constants'
import { cleanupFonts } from 'utils/fonts.utils'

import {
  ORDER_BUMPS_TABS_KEYS,
  ORDER_BUMPS_SHADOW_KEYS,
  ORDER_BUMPS_FIELDS_KEYS,
  ORDER_BUMPS_ARROW_TYPES,
  ORDER_BUMPS_PRODUCT_IMAGE_KEYS,
  ORDER_BUMPS_VIEW_TYPES_KEYS,
  ORDER_BUMPS_ALIGNMENT_FIELD_KEY,
  ORDER_BUMPS_ALIGNMENT_TYPES,
  ORDER_BUMPS_CHECKBOX_STYLE_OPTION_KEYS,
  ORDER_BUMPS_VIEW_FIELD_KEY,
  ORDER_BUMPS_Z_INDEXES,
} from 'constants/orderBumps.constants'

import {
  getKeyframes,
  getHeaderAnimation,
  getPriceAnimation,
  getButtonAnimation,
  getArrowAnimation,
  getBorderStyles,
} from './utils/order-bumps-animation.utils'

import {
  getMobilePreviewStyles,
  getFirstViewStyles,
  getSecondaryViewStyles,
  getThirdViewStyles,
  getFourthViewStyles,
  getFifthViewStyles,
  getSevenViewStyles,
  isDescriptionPresent,
  isOnlyButtonInBump,
} from './utils/order-bumps-views.utils'

const { background, header, button, product, additionalText, arrows } = ORDER_BUMPS_TABS_KEYS

const { arrowsColor, arrowsTransparency } = ORDER_BUMPS_FIELDS_KEYS.arrows

const { backgroundColor, backgroundImage, backgroundShadow, backgroundTransparency } =
  ORDER_BUMPS_FIELDS_KEYS.background

const { headerFontStyle, headerFontFamily, headerColor, headerFontSize } = ORDER_BUMPS_FIELDS_KEYS.header

const {
  buttonColor,
  buttonButtonColor,
  buttonFontFamily,
  buttonFontStyle,
  buttonFontSize,
  buttonViewStyle,
  buttonRadius,
  buttonCheckboxBorder,
  buttonCheckboxStyle,
  buttonCheckboxColor,
} = ORDER_BUMPS_FIELDS_KEYS.button

const { additionalTextRadius, additionalTextTransparency, additionalTextBackgroundColor } =
  ORDER_BUMPS_FIELDS_KEYS.additionalText

const {
  nameColor,
  descriptionColor,
  priceColor,
  oldPriceColor,
  productImageState,
  nameActive,
  priceActive,
  oldPriceActive,
} = ORDER_BUMPS_FIELDS_KEYS.product

const getButtonStyles = (theme) => {
  const isFilled = BUTTON_STYLE.filled === theme[button][buttonViewStyle]

  return {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    border: isFilled ? 'none' : `1px solid ${theme[button][buttonButtonColor]}`,
    backgroundColor: isFilled ? theme[button][buttonButtonColor] : 'none',
    borderRadius: `${theme[button][buttonRadius]}px`,
    width: '100%',
    height: '70px',
    marginTop: isOnlyButtonInBump(theme) ? 0 : '30px',
    padding: '20px',
    zIndex: ORDER_BUMPS_Z_INDEXES.button,
    cursor: 'pointer',
    ...getButtonAnimation(theme),
  }
}

const getArrowsStyles = (theme, type) => ({
  color: theme[arrows][arrowsColor],
  opacity: theme[arrows][arrowsTransparency],
  ...getArrowAnimation(theme, type),
  '& svg': {
    fill: theme[arrows][arrowsColor],
  },
})

const getProductPriceStyles = (theme) => ({
  rootProductPriceContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    zIndex: ORDER_BUMPS_Z_INDEXES.product,
    display: theme[product][priceActive] || theme[product][oldPriceActive] ? 'flex' : 'none',
  },
  rootProductPriceContainerWrapped: {
    flexWrap: 'wrap',
    rowGap: '10px',
    justifyContent: 'flex-start',
  },
  rootProductPrice: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '30px',
    display: 'flex',
  },
  rootProductPriceOld: {
    fontSize: '13px',
    display: theme[product][oldPriceActive] ? 'block' : 'none',
    color: theme[product][oldPriceColor],
    textDecoration: 'line-through',
  },
  rootProductPriceNew: {
    fontSize: '30px',
    fontWeight: 500,
    lineHeight: 1,
    fontFamily: 'var(--elo-font-sans-serif)',
    display: theme[product][priceActive] ? 'block' : 'none',
    color: theme[product][priceColor],
    ...getPriceAnimation(theme),
  },
  rootProductPriceNetto: {
    fontFamily: 'Montserrat Reg',
    fontSize: '12px',
    marginLeft: '5px',
  },
  rootProductPriceType: {
    fontFamily: 'Montserrat Reg',
    fontSize: '13px',
    fontStyle: 'italic',
    color: '#9e9e9e',
  },
  rootFloatingProductPriceOld: {
    display: 'none',
  },
})

const getProductStyles = (theme) => {
  const withImage = theme[product][productImageState] === ORDER_BUMPS_PRODUCT_IMAGE_KEYS.display
  const withLeftAlign = ORDER_BUMPS_ALIGNMENT_TYPES.left === theme[ORDER_BUMPS_ALIGNMENT_FIELD_KEY]
  const isFourthView = theme[ORDER_BUMPS_VIEW_FIELD_KEY] === ORDER_BUMPS_VIEW_TYPES_KEYS.fourth

  return {
    rootProduct: {
      display: 'flex',
      margin: withImage || theme[product][nameActive] || isDescriptionPresent(theme) ? '30px 100px 0 100px' : '0px',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: withLeftAlign ? 'row' : 'row-reverse',
      zIndex: ORDER_BUMPS_Z_INDEXES.product,
    },
    rootProductImage: {
      display: withImage ? 'flex' : 'none',
      justifyContent: 'center',
      alignItems: 'center',
      width: '60px',
      minWidth: '60px',
      height: '60px',
      borderRadius: '4px',
      marginRight:
        (theme[product][nameActive] || theme.isEventInfoProvided || isDescriptionPresent(theme) || isFourthView) &&
        withLeftAlign
          ? '20px'
          : '0px',
      marginLeft:
        (theme[product][nameActive] || theme.isEventInfoProvided || isDescriptionPresent(theme) || isFourthView) &&
        !withLeftAlign
          ? '20px'
          : '0px',
      objectFit: 'cover',
    },
    rootProductDetailsContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: withImage ? 'flex-start' : 'center',
      flexDirection: 'column',
    },
    rootProductName: {
      display: theme[product][nameActive] ? 'block' : 'none',
      fontFamily: 'Montserrat Medium',
      fontSize: '16px',
      color: theme[product][nameColor],
      lineHeight: 1,
      marginBottom: 6,
    },
    rootProductNameMobile: {
      fontSize: '16px',
      marginBottom: 0,
    },
    rootProductDescription: {
      display: isDescriptionPresent(theme) ? 'block' : 'none',
      textAlign: withImage ? 'left' : 'center',
      marginTop: '5px',
      fontSize: '14px',
      fontFamily: 'Montserrat Reg',
      lineHeight: '1.24',
      color: theme[product][descriptionColor],
      '& > p': {
        marginBottom: 0,
      },
    },
    rootProductDescriptionWithName: {
      display: 'block',
    },
    rootProductDescriptionSeparate: {
      display: 'none',
    },
    rootEventWithName: {
      display: ['block', '!important'],
    },
    rootEventSeparate: {
      display: ['none', '!important'],
    },
  }
}

const getCheckBoxStyles = (theme) => {
  switch (theme[button][buttonCheckboxStyle]) {
    case ORDER_BUMPS_CHECKBOX_STYLE_OPTION_KEYS.notFilled: {
      return {
        marginBottom: '25px',
        '& input:checked ~ .custom-check-mark': {
          backgroundColor: 'white',
          borderColor: 'white',
          '&:before': {
            border: `solid ${theme[button][buttonCheckboxColor]}`,
            borderWidth: '0 4px 4px 0',
          },
        },
        '& .custom-check-mark': {
          width: '24px',
          height: '24px',
          borderRadius: '2px',
          boxShadow: `0px 0px 0px 2px ${theme[button][buttonCheckboxBorder]}`,
          border: 'none',
          '&:before': {
            left: '50%',
            top: '50%',
            width: '10px',
            height: '14px',
            borderWidth: '0 4px 4px 0',
          },
        },
      }
    }

    case ORDER_BUMPS_CHECKBOX_STYLE_OPTION_KEYS.rounded: {
      return {
        marginBottom: '25px',
        '& input:checked ~ .custom-check-mark': {
          backgroundColor: 'white',
          borderColor: 'white',
          borderRadius: '50%',
          '&:before': {
            border: `solid ${theme[button][buttonCheckboxColor]}`,
            borderWidth: '0 4px 4px 0',
          },
        },
        '& .custom-check-mark': {
          width: '24px',
          height: '24px',
          borderRadius: '50%',
          boxShadow: `0px 0px 0px 4px ${theme[button][buttonCheckboxBorder]}`,
          border: 'none',
          '&:before': {
            top: '50%',
            left: '50%',
            width: '12px',
            height: '26px',
            borderWidth: '0 4px 4px 0',
          },
        },
      }
    }

    case ORDER_BUMPS_CHECKBOX_STYLE_OPTION_KEYS.sharp: {
      return {
        marginBottom: '30px',
        marginRight: '5px',
        borderRadius: '0px',
        '& input:checked ~ .custom-check-mark': {
          backgroundColor: theme[button][buttonCheckboxColor],
          borderColor: theme[button][buttonCheckboxColor],
        },
        '& .custom-check-mark': {
          width: '30px',
          height: '30px',
          borderRadius: '0px',
          boxShadow: `0px 0px 0px 2px ${theme[button][buttonCheckboxBorder]}`,
          border: 'none',
          '&:before': {
            top: '50%',
            left: '50%',
            width: '13px',
            height: '25px',
            borderWidth: '0 4px 4px 0',
          },
        },
      }
    }

    case ORDER_BUMPS_CHECKBOX_STYLE_OPTION_KEYS.big: {
      return {
        marginBottom: '32px',
        marginRight: '10px',
        borderRadius: '0px',
        '& input:checked ~ .custom-check-mark': {
          backgroundColor: 'white',
          borderColor: 'white',
          '&:before': {
            border: `solid ${theme[button][buttonCheckboxColor]}`,
            borderWidth: '0 8px 8px 0',
          },
        },
        '& .custom-check-mark': {
          width: '32px',
          height: '32px',
          borderRadius: '0px',
          boxShadow: `0px 0px 0px 4px ${theme[button][buttonCheckboxBorder]}`,
          border: 'none',
          '&:before': {
            top: '50%',
            left: '50%',
            width: '20px',
            height: '34px',
            borderWidth: '0 4px 4px 0',
          },
        },
      }
    }

    case ORDER_BUMPS_CHECKBOX_STYLE_OPTION_KEYS.filled:
    default: {
      return {
        marginBottom: '25px',
        '& input:checked ~ .custom-check-mark': {
          backgroundColor: theme[button][buttonCheckboxColor],
          borderColor: theme[button][buttonCheckboxColor],
        },
        '& .custom-check-mark': {
          width: '24px',
          height: '24px',
          borderRadius: '2px',
          boxShadow: `0px 0px 0px 2px ${theme[button][buttonCheckboxBorder]}`,
          border: 'none',
          '&:before': {
            left: '50%',
            top: '50%',
            width: '10px',
            height: '14px',
            borderWidth: '0 4px 4px 0',
          },
        },
      }
    }
  }
}

const getRootStyles = (theme) => {
  const withLeftAlign = ORDER_BUMPS_ALIGNMENT_TYPES.left === theme[ORDER_BUMPS_ALIGNMENT_FIELD_KEY]

  return {
    root: {
      width: '100%',
      height: '100%',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '30px',
      minHeight: '100px',
      overflow: 'hidden',
      boxShadow:
        theme[background][backgroundShadow] === ORDER_BUMPS_SHADOW_KEYS.no ? 'none' : '0 1px 8px 0 rgba(0, 0, 0, 0.13)',
    },
    rootNoShadow: {
      boxShadow: 'none',
    },
    pricingPlans: {
      minWidth: '340px',
      padding: '15px 0',
    },
    rootBackgroundBorder: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      zIndex: ORDER_BUMPS_Z_INDEXES.backgroundImage,
      ...getBorderStyles(theme),
    },
    rootBackgroundImage: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      backgroundImage: getBackgroundImageCss(theme[background][backgroundImage]),
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      zIndex: ORDER_BUMPS_Z_INDEXES.backgroundImage,
      opacity: theme[background][backgroundTransparency],
    },
    rootBackground: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      zIndex: ORDER_BUMPS_Z_INDEXES.background,
      backgroundColor: theme[background][backgroundColor],
      opacity: theme[background][backgroundTransparency],
    },
    rootHeader: {
      fontFamily: cleanupFonts(theme[header][headerFontFamily]),
      ...getFontStyles(theme[header][headerFontStyle]),
      color: theme[header][headerColor],
      fontSize: `${theme[header][headerFontSize]}px`,
      zIndex: ORDER_BUMPS_Z_INDEXES.header,
      maxWidth: '840px',
      overflowWrap: 'break-word',
      lineHeight: 1,
      ...getHeaderAnimation(theme),
    },
    rootButton: {
      ...getButtonStyles(theme),
    },
    buttonText: {
      fontFamily: cleanupFonts(theme[button][buttonFontFamily]),
      ...getFontStyles(theme[button][buttonFontStyle]),
      color: theme[button][buttonColor],
      fontSize: `${theme[button][buttonFontSize]}px`,
      zIndex: ORDER_BUMPS_Z_INDEXES.button,
    },
    rootCheckboxWithText: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      alignItems: 'center',
      cursor: 'pointer',
      '&:hover': {
        '& $rootRadio': {
          '& .custom-check-mark': {
            '&:before': {
              backgroundColor: ['#D5D5D5', '!important'],
            },
          },
          '& .radio-field__control--active': {
            '& .custom-check-mark': {
              '&:before': {
                backgroundColor: ['#FFFFFF', '!important'],
              },
            },
          },
        },
      },
    },
    rootCheckboxImportance: {},
    rootCheckbox: {
      '&$rootCheckboxImportance': {
        ...getCheckBoxStyles(theme),
      },
    },
    rootRadio: {
      marginBottom: ['25px', '!important'],
      '& input:checked ~ .custom-check-mark': {
        backgroundColor: [theme[button][buttonCheckboxColor], '!important'],
        borderColor: `${theme[button][buttonCheckboxColor]} !important`,
      },
      '& .custom-check-mark': {
        borderColor: `${theme[button][buttonCheckboxBorder]} !important`,
      },
    },
    rootArrowsLeft: {
      marginRight: '25px',
    },
    leftArrows: {
      ...getArrowsStyles(theme, ORDER_BUMPS_ARROW_TYPES.left),
    },
    rootArrowsRight: {
      marginLeft: '25px',
    },
    rightArrows: {
      ...getArrowsStyles(theme, ORDER_BUMPS_ARROW_TYPES.right),
      transform: 'rotate(180deg)',
    },
    rootAdditionalText: {
      width: '100%',
      height: '100%',
      position: 'relative',
      marginTop: '20px',
      borderRadius: `${theme[additionalText][additionalTextRadius]}px`,
    },
    additionalTextBg: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      zIndex: ORDER_BUMPS_Z_INDEXES.backgroundImage,
      backgroundColor: theme[additionalText][additionalTextBackgroundColor],
      opacity: theme[additionalText][additionalTextTransparency],
      borderRadius: `${theme[additionalText][additionalTextRadius]}px`,
    },
    additionalText: {
      width: '100%',
      position: 'relative',
      padding: '20px 40px',
      zIndex: ORDER_BUMPS_Z_INDEXES.additionalText,
      wordBreak: 'break-word',
      overflow: 'hidden',
      color: COLORS.eloGrey70,
    },
    viewImageHelp: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '270px',
      height: '270px',
      margin: !withLeftAlign ? '0 30px 0 0' : '0 0 0 30px',
      padding: '50px',
      borderRadius: '4px',
      border: '1px dashed rgba(0, 0, 0, 0.15)',
      backgroundColor: COLORS.eloGrey20,
    },
    viewImageHelpIcon: {
      marginBottom: '10px',
      color: '#e0e0e0',
      fontSize: '80px',
    },
    viewImageHelpText: {
      fontSize: '13px',
      fontStyle: 'italic',
      lineHeight: '1.15',
      textAlign: 'center',
      color: '#9e9e9e',
    },
    rootChangePaymentPlanButton: {
      position: 'absolute',
      minWidth: '176px',
      height: '30px',
      padding: '8px 15px !important',
      lineHeight: [1, '!important'],
      fontFamily: 'Montserrat Reg',
      fontWeight: 400,
      color: [theme[button][buttonButtonColor], '!important'],
      border: `1px solid ${theme[button][buttonButtonColor]} !important`,
      borderRadius: `${theme[button][buttonRadius]}px !important`,
      fontSize: '13px',
      zIndex: ORDER_BUMPS_Z_INDEXES.button,
      left: '110%',
    },
    rootChangePaymentPlanButtonRelative: {
      position: 'relative',
    },
    rootPriceWithPlansWrapper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
    },
  }
}

// TODO: update with using createUseStyles
const getEventsStyles = (theme) => ({
  rootEvent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    zIndex: ORDER_BUMPS_Z_INDEXES.product,
    marginTop: '25px',
  },
  rootEventName: {
    display: 'block',
    fontFamily: 'Montserrat Medium',
    fontSize: '16px',
    lineHeight: 1,
    color: theme[product][nameColor],
  },
  rootEventDetails: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5px',
  },
  eventLocationDetails: {
    display: 'block',
    marginTop: '5px',
    fontSize: '14px',
    fontFamily: 'Montserrat Reg',
    lineHeight: '1.14',
    color: theme[product][descriptionColor],
  },
  eventTimeDetails: {
    display: 'block',
    marginTop: '5px',
    fontSize: '14px',
    fontFamily: 'Montserrat Reg',
    lineHeight: '1.14',
    color: theme[product][descriptionColor],
  },
})

// TODO: update with using createUseStyles
const orderBumpItemStyles = (theme = {}) => {
  let stylesPerView = null

  switch (theme[ORDER_BUMPS_VIEW_FIELD_KEY]) {
    case ORDER_BUMPS_VIEW_TYPES_KEYS.seven: {
      stylesPerView = getSevenViewStyles(theme)
      break
    }
    case ORDER_BUMPS_VIEW_TYPES_KEYS.fifth: {
      stylesPerView = getFifthViewStyles(theme)
      break
    }
    case ORDER_BUMPS_VIEW_TYPES_KEYS.fourth: {
      stylesPerView = getFourthViewStyles(theme)
      break
    }
    case ORDER_BUMPS_VIEW_TYPES_KEYS.third: {
      stylesPerView = getThirdViewStyles(theme)
      break
    }
    case ORDER_BUMPS_VIEW_TYPES_KEYS.sec: {
      stylesPerView = getSecondaryViewStyles(theme)
      break
    }
    case ORDER_BUMPS_VIEW_TYPES_KEYS.first:
    default: {
      stylesPerView = getFirstViewStyles(theme)
    }
  }

  return {
    ...getKeyframes(theme),
    ...getProductStyles(theme),
    ...getProductPriceStyles(theme),
    ...getRootStyles(theme),
    ...getEventsStyles(theme),
    ...getMobilePreviewStyles(theme),
    ...stylesPerView,
  }
}

export default orderBumpItemStyles
