import React from 'react'

const LongArrows = () => (
  <svg
    width='63px'
    height='15px'
    viewBox='0 0 63 15'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g id='Page-1' stroke='none' strokeWidth='1' fillRule='evenodd'>
      <polyline
        id='5'
        points='54.3780822 0 49.2712329 0 54.7150685 4.81986301 0 4.81986301 0 9.85479452 54.7150685 9.85479452 49.2712329 14.6746575 54.3780822 14.6746575 63.0335616 7.33767123 54.3780822 0'
      />
    </g>
  </svg>
)

export default LongArrows
