import React from 'react'

const WavesArrows = () => (
  <svg
    width='88px'
    height='25px'
    viewBox='0 0 88 25'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g id='Page-1' stroke='none' strokeWidth='1' fillRule='evenodd'>
      <path
        d='M58.8709677,-1.98951966e-13 L87.9032258,12.5 L58.8709677,25 C58.8961475,24.958892 58.9244747,24.917784 58.9528019,24.8735138 C64.048555,17.3760435 64.048555,7.62395649 58.9528019,0.123324058 C58.9244747,0.0822160385 58.8961475,0.0411080192 58.8709677,-1.98951966e-13 L58.8709677,-1.98951966e-13 Z M29.8387097,-1.98951966e-13 L58.8709677,12.5 L29.8387097,25 C29.8670369,24.958892 29.8953641,24.917784 29.9236914,24.8735138 C35.0194444,17.3760435 35.0194444,7.62395649 29.9236914,0.123324058 C29.8953641,0.0822160385 29.8670369,0.0411080192 29.8387097,-1.98951966e-13 L29.8387097,-1.98951966e-13 Z M-4.95603558e-13,-1.98951966e-13 L29.0322581,12.5 L-4.95603558e-13,25 C0.0276980313,24.958892 0.055710813,24.917784 0.084038345,24.8735138 C5.17890235,17.3760435 5.17890235,7.62395649 0.084038345,0.123324058 C0.055710813,0.0822160385 0.0276980313,0.0411080192 -4.95603558e-13,-1.98951966e-13 L-4.95603558e-13,-1.98951966e-13 Z'
        id='6'
      />
    </g>
  </svg>
)

export default WavesArrows
