import React, { Component, Fragment } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import withStyles from 'react-jss'

import PricingPlansList from 'shared/components/pricing-plans/PricingPlansList'
import { RadioField } from '@elo-kit/components/form/radio-field/RadioField'
import { CheckboxField } from '@elo-kit/components/form/checkbox-field/CheckboxField'
import { UPSELLS_TYPES } from 'constants/productsShared.constants'

import { cleanupHTMLStyleFonts } from 'utils/fonts.utils'
import { parseStringToBool } from 'utils/helpers.utils'
import { PAYMENT_PLANS } from 'constants/pricingPlans.constants'
import ProductAddons from './ProductAddons'
import bundleStyles from './bundleStyles'

@observer
class Bundle extends Component {
  render() {
    const {
      currenciesStore,
      productUpsell,
      productUpsell: { ticket, ticketDate, ticketId, ticketDateId, ticketsCount },
      value,
      templateConfig,
      coupon,
      preview,
      selectUpsell = () => {},
      deselectUpsell = () => {},
      selectedPlanId,
      upsell,
      pricingPlans = [],
      classes,
      classes: { productInfoBordered, productInfoFullSize },
      productAddons,
      countries,
      upsellType,
      ownPlans,
      themeStore,
    } = this.props

    const ticketInfo = ticketId
      ? {
          ticket,
          ticketDate,
          ticketId,
          ticketDateId,
          ticketsCount,
        }
      : {}

    const { prefs: planPrefs = {}, currencyId, id: planId, useNetPrice, form } = pricingPlans[0] || {}

    const oneTimePaymentOnly = pricingPlans.length === 1 && form === PAYMENT_PLANS.oneTime
    const showPlans = !oneTimePaymentOnly && pricingPlans && pricingPlans.length > 0
    const couponApplied = (coupon || {}).applied && (coupon.data || {}).upsells
    const isSelected = value === productUpsell.id && !preview
    const priceClasses = classNames({
      [classes.priceCrossed]: couponApplied,
    })
    const showOldPrice = planPrefs.oldPrice && planPrefs.oldPrice > 0

    const color = themeStore && themeStore.ppTemplate?.theme.paymentPageColor
    const radioButtonStyle = color
      ? {
          backgroundColor: color,
          borderColor: color,
        }
      : {}

    const isBump = upsellType === UPSELLS_TYPES.bump
    const productInfoClasses = classNames({
      [productInfoBordered]: showPlans,
      [productInfoFullSize]: !showPlans,
      [classes.wrapperCursor]: isBump,
    })

    const product = ownPlans
      ? {
          ...upsell,
          pricingPlans: [...pricingPlans],
        }
      : upsell

    const bumpIsRequired = productUpsell.prefs?.required

    const onCheckFieldChange = (e) => {
      if (e) {
        e.preventDefault()
        e.stopPropagation()
      }

      if (isBump && value && !bumpIsRequired) {
        return deselectUpsell(value)
      }

      return selectUpsell(product, planId, productUpsell.id, productUpsell.position, ticketInfo)
    }

    const controlField = isBump ? (
      <CheckboxField
        id={productUpsell.id}
        checked={String(value) === String(productUpsell.id)}
        label={upsell.name}
        onChange={onCheckFieldChange}
        disabled={bumpIsRequired}
      />
    ) : (
      <RadioField
        style={radioButtonStyle}
        id={productUpsell.id}
        value={value}
        label={upsell.name}
        onChange={onCheckFieldChange}
        className={classes.radioField}
      />
    )

    const wrapperClasses = classNames(classes.productUpsell, {
      [classes.productUpsellActive]: isSelected,
    })

    return (
      <div className={wrapperClasses}>
        <div className={classes.wrapper}>
          {templateConfig.labelEnabled && templateConfig.label && (
            <div className={classes.label}>{templateConfig.label}</div>
          )}
          <div className={classes.innerContent}>
            <div className={productInfoClasses} onClick={isBump ? onCheckFieldChange : null}>
              <div className={classes.productInfoTitleContainer}>
                <div className={classes.productInfoTitle}>{preview ? upsell.name : controlField}</div>
                {oneTimePaymentOnly && (
                  <div className={classes.productInfoPrice}>
                    {showOldPrice && (
                      <span className={classes.oldPrice} translate='no'>
                        {currenciesStore.convertToPrice(planPrefs.oldPrice, currencyId)}
                      </span>
                    )}
                    <span className={priceClasses} translate='no'>
                      {currenciesStore.convertToPrice(parseFloat(planPrefs.price), currencyId)}
                    </span>
                    {useNetPrice && <i>{I18n.t('react.shared.netto')}</i>}
                    {couponApplied && (
                      <Fragment>
                        <div className={classes.couponApplied}>
                          <i className='fas fa-check' />
                          {I18n.t('react.shared.coupon.applied')}
                        </div>
                      </Fragment>
                    )}
                  </div>
                )}
              </div>
              {parseStringToBool(templateConfig.showProductNames) && (
                <div className={classes.productList}>
                  <ProductAddons productAddons={productAddons} countries={countries} />
                </div>
              )}
              {upsell.description && (
                <div
                  className={classes.productDescription}
                  dangerouslySetInnerHTML={{ __html: cleanupHTMLStyleFonts(upsell.description) }}
                />
              )}
              {parseStringToBool(templateConfig.buyButtonEnabled) && (
                <button
                  onClick={() => selectUpsell(product, planId, productUpsell.id, productUpsell?.position, ticketInfo)}
                  className={classes.addUpsellButton}
                >
                  {templateConfig.buttonText}
                </button>
              )}
            </div>
            {showPlans && (
              <div className={classes.pricingPlans}>
                <PricingPlansList
                  pricingPlans={pricingPlans}
                  selectedId={selectedPlanId}
                  forForm={isSelected}
                  coupon={((coupon || {}).data || {}).upsells && coupon}
                  onSelectPlan={(planId) =>
                    selectUpsell(product, planId, productUpsell.id, productUpsell?.position, ticketInfo)
                  }
                  keepEnabled
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(bundleStyles)(Bundle)
