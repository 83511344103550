import {
  ORDER_BUMPS_TABS_KEYS,
  ORDER_BUMPS_FIELDS_KEYS,
  ORDER_BUMPS_PRODUCT_IMAGE_KEYS,
  ORDER_BUMPS_ALIGNMENT_FIELD_KEY,
  ORDER_BUMPS_ALIGNMENT_TYPES,
  ORDER_BUMPS_Z_INDEXES,
} from 'constants/orderBumps.constants'

import { getCustomFilledButtonAnimation } from './order-bumps-animation.utils'

const { product, header, background } = ORDER_BUMPS_TABS_KEYS

const { productImageState, nameActive, priceActive, oldPriceActive } = ORDER_BUMPS_FIELDS_KEYS.product

const { backgroundTransparency, backgroundImage } = ORDER_BUMPS_FIELDS_KEYS.background

const { headerText } = ORDER_BUMPS_FIELDS_KEYS.header

export const isOnlyButtonInBump = (theme = {}) => {
  const {
    product: { nameActive, productImageState, priceActive, descriptionActive } = {},
    header: { headerText } = {},
  } = theme
  return (
    !nameActive &&
    !priceActive &&
    !descriptionActive &&
    productImageState === ORDER_BUMPS_PRODUCT_IMAGE_KEYS.hide &&
    !headerText
  )
}

export const isDescriptionPresent = (theme) => Boolean(theme.isDescriptionProvided)

export const isHeaderActive = (theme = {}) => Boolean(theme[header][headerText])

export const getMobilePreviewStyles = (theme = {}) => {
  let viewDependentStyles = {}
  const productBlockActive =
    theme[product][productImageState] === ORDER_BUMPS_PRODUCT_IMAGE_KEYS.display ||
    theme[product][nameActive] ||
    isDescriptionPresent(theme)

  viewDependentStyles = {
    rootProductDescriptionSeparate: {
      display: theme.isMobilePreview && isDescriptionPresent(theme) ? 'block' : 'none',
      zIndex: ORDER_BUMPS_Z_INDEXES.product,
      marginTop: '20px',
    },
    rootProductDescriptionWithName: {
      display: theme.isMobilePreview || !isDescriptionPresent(theme) ? 'none' : 'block',
    },
  }

  return {
    rootMobile: {
      zIndex: 1,
      padding: '20px',
      '& $rootEvent': {
        marginTop: '15px',
      },
      '& $rootEventSeparate': {
        display: ['block', '!important'],
      },
      '& $rootEventWithName': {
        display: ['none', '!important'],
      },
    },
    rootHeaderMobile: {
      lineHeight: 1,
      // maxWidth: ['315px', '!important'],
      // minWidth: ['315px', '!important'],
    },
    rootProductMobile: {
      margin: productBlockActive ? '20px 0 0 0' : '0px',
      width: '100%',
      justifyContent: 'flex-start',
      '& $rootProductImage': {
        marginRight: theme[product][nameActive] ? '15px' : '0px',
      },
    },
    rootArrowsLeftMobile: {
      display: 'none',
    },
    rootArrowsRightMobile: {
      display: 'none',
    },
    rootButtonMobile: {
      marginTop: [isOnlyButtonInBump(theme) ? 0 : '20px', '!important'],
      '& $buttonText': {
        lineHeight: 1,
      },
    },
    rootAdditionalTextMobile: {
      '& $additionalText': {
        padding: '20px',
      },
    },
    rootProductPriceContainerMobile: {
      width: '100%',
      justifyContent: 'flex-start',
      '& $rootProductPrice': {
        marginTop: '20px',
        alignItems: 'flex-start',
      },
      '& $rootProductPriceOld': {
        display: 'none',
      },
      '& $rootFloatingProductPriceOld': {
        display: theme[product][oldPriceActive] ? 'block' : 'none',
        marginTop: '-15px',
      },
    },
    ...viewDependentStyles,
  }
}

export const getFirstViewStyles = (theme) => ({
  pricingPlanWrapperMobile: {
    display: 'none',
    '& .tooltip-more__close': {
      zIndex: ORDER_BUMPS_Z_INDEXES.tooltipBackdrop,
    },
  },
  pricingPlanWrapperDesktop: {
    display: 'block',
    '& .tooltip-more__close': {
      zIndex: ORDER_BUMPS_Z_INDEXES.tooltipBackdrop,
    },
  },
  rootProductPriceNewFirst: {
    marginTop: '30px',
  },
  rootProductFirst: {
    alignItems: isDescriptionPresent(theme) ? 'flex-start' : 'center',

    '& $rootEvent': {
      marginTop: theme[product][nameActive] || isDescriptionPresent(theme) ? '25px' : '0px',
    },
  },
  rootProductMobileFirst: {
    '&$rootProductFirst': {
      alignItems: 'center',
    },
  },
  rootProductPriceContainerFirst: {
    '& $pricingPlanWrapperDesktop': {
      display: 'block',
    },
    '& $rootFloatingProductPriceOld': {
      paddingLeft: '5px',
    },
  },
  rootProductPriceContainerMobileFirst: {
    '& $rootProductPriceNewFirst': {
      marginTop: '20px',
    },
    '& $pricingPlanWrapperDesktop': {
      display: ['none', '!important'],
    },
    '&$pricingPlanWrapperMobile': {
      display: ['flex', '!important'],
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginTop: '10px',
    },
    '& $rootChangePaymentPlanButton': {
      position: 'initial',
    },
  },
})

export const getSecondaryViewStyles = (theme) => {
  const withImage = theme[product][productImageState] === ORDER_BUMPS_PRODUCT_IMAGE_KEYS.display
  const withLeftAlign = ORDER_BUMPS_ALIGNMENT_TYPES.left === theme[ORDER_BUMPS_ALIGNMENT_FIELD_KEY]

  return {
    rootProductSecContainer: {
      width: '100%',
    },
    rootSecViewRoot: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      flexDirection: !withLeftAlign ? 'row' : 'row-reverse',
      zIndex: ORDER_BUMPS_Z_INDEXES.newView,
      '& $rootHeader': {
        lineHeight: '1',
        width: '100%',
        maxWidth: '100%',
      },
    },
    rootProductPriceContainerMobileSecBot: {
      display: 'none',
    },
    rootSecViewRootMobile: {
      flexDirection: 'column',
      maxHeight: 'initial',
      '& $viewImageHelp': {
        margin: '0',
        width: '316px',
        height: '316px',
      },
      '& $rootSecViewImgRootImg': {
        margin: '0',
        minWidth: '220px',
      },
      '& $rootProductSec': {
        marginTop: '20px',
        flexDirection: withLeftAlign ? 'row' : 'row-reverse',

        '& $pricingPlanWrapperMobile': {
          display: ['none', '!important'],
        },
      },
      '& $rootProductDescriptionSec': {
        marginTop: '20px',
      },
      '& $rootProductImageContainerSec': {
        marginRight: '15px',
      },
      '& $rootProductImage': {
        minWidth: '60px',
        width: '60px',
        height: '60px',
      },
      '& $rootProductDetailsContainerSec': {
        height: '60px',
        justifyContent: 'center',
        '& $rootProductNameSec': {
          marginBottom: '0px',
        },
      },
      '& $rootProductPriceContainerMobileSecBot': {
        display: [theme[product][priceActive] || theme[product][oldPriceActive] ? 'flex' : 'none', '!important'],
        justifyContent: 'flex-start',
        marginTop: '15px',
      },
      '& $rootProductPriceContainerMobileSec': {
        display: 'none',
      },
      '& $rootArrowsRightMobile': {
        display: 'block',
      },
    },
    rootSecViewMobileHeaderRoot: {
      display: 'none',
    },
    rootSecViewMobileHeaderActive: {
      display: 'block',
      zIndex: ORDER_BUMPS_Z_INDEXES.newView,
      width: '100%',
      '& $rootHeaderMobileSecView': {
        display: 'block',
        marginBottom: '15px',
        width: '100%',
        textAlign: 'center',
        maxWidth: '100%',
      },
    },
    rootHeaderMobileSecView: {
      display: 'none',
    },
    rootSecViewContentRoot: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      width: '100%',
    },
    rootSecViewImgRootImg: {
      width: '270px',
      height: '270px',
      minWidth: '270px',
      margin: !withLeftAlign ? '0 30px 0 0' : '0 0 0 30px',
      borderRadius: '4px',
      opacity: theme[background][backgroundTransparency],
      backgroundImage: `url(${theme[background][backgroundImage]})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
    },
    rootProductSec: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row',
      marginTop: '25px',
      '& $rootProductImage': {
        width: '90px',
        minWidth: '90px',
        height: '90px',
        marginRight: '0px',
        marginLeft: '0px',
      },
    },
    rootProductMobileSec: {},
    rootProductPriceContainerSec: {
      alignItems: 'flex-start',
    },
    rootProductImageContainerSec: {
      display: withImage ? 'flex' : 'none',
      marginRight: '30px',
    },
    pricingPlanWrapperMobile: {
      display: 'none',
      '& .tooltip-more__close': {
        zIndex: ORDER_BUMPS_Z_INDEXES.tooltipBackdrop,
      },
    },
    pricingPlanWrapperDesktop: {
      display: 'flex',
      '& .tooltip-more__close': {
        zIndex: ORDER_BUMPS_Z_INDEXES.tooltipBackdrop,
      },
    },
    rootProductDetailsContainerSec: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      minHeight: '90px',
      position: 'relative',
      width: '100%',
      '& $rootProductNameSec': {
        marginBottom: '15px',
      },
      '& $pricingPlanWrapperMobile': {
        display: 'none',
      },
      '& $pricingPlanWrapperDesktop': {
        display: 'flex',
        '& $rootChangePaymentPlanButton': {
          left: 'initial',
          right: '0px',
        },
      },
    },
    rootProductNameSec: {},
    rootProductDescriptionSec: {
      marginTop: '30px',
      textAlign: 'left',
    },
    rootProductPriceSec: {
      marginTop: 0,
      alignItems: 'flex-start',
    },
    rootProductPriceNewSec: {
      fontSize: '24px',
    },
    rootProductPriceContainerMobileSec: {
      '&$pricingPlanWrapperMobile': {
        display: 'flex',
        '& $rootChangePaymentPlanButton': {
          position: 'initial',
          marginTop: '10px',
        },
      },
      '& $pricingPlanWrapperDesktop': {
        display: ['none', '!important'],
      },
    },
    rootFloatingProductPriceOldSec: {
      paddingLeft: '5px',
    },
    rootButtonSec: {
      height: '60px',
      marginTop: '25px',
      justifyContent: 'space-between',
      padding: '20px',
    },
  }
}

export const getThirdViewStyles = (theme) => {
  const maxWidth = '485px'
  const width = '100%'
  const maxWidthMobile = '315px'
  const withLeftAlign = ORDER_BUMPS_ALIGNMENT_TYPES.left === theme[ORDER_BUMPS_ALIGNMENT_FIELD_KEY]

  return {
    rootThirdViewRoot: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      zIndex: ORDER_BUMPS_Z_INDEXES.newView,
      maxWidth,
      width,
    },
    rootThirdViewHeaderRoot: {
      zIndex: ORDER_BUMPS_Z_INDEXES.newView,
      lineHeight: '1',
      marginBottom: '25px',
      '& $rootHeader': {
        lineHeight: '1',
        maxWidth,
        // minWidth: maxWidth,
      },
    },
    rootThirdViewRootMobile: {
      maxWidth,
      '& $rootThirdViewImgRootImg': {
        width: '316px',
        height: '316px',
        margin: '0px',
        borderRadius: '4px',
      },
      '& $thirdImageHelp': {
        width: '316px',
        height: '316px',
        margin: '0px',
      },
      '& $rootArrowsRightMobile': {
        display: 'block',
      },
      '& $rootHeader': {
        maxWidth: maxWidthMobile,
        // minWidth: maxWidthMobile,
      },
    },
    rootThirdViewImgRoot: {
      marginBottom: '25px',
    },
    rootThirdViewImgRootImg: {
      width: maxWidth,
      height: '270px',
      margin: '0px',
      opacity: theme[background][backgroundTransparency],
      backgroundImage: `url(${theme[background][backgroundImage]})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
    },
    thirdImageHelp: {
      width: maxWidth,
      height: '270px',
      margin: '0px',
    },
    thirdImageHelpIcon: {},
    thirdImageHelpText: {},
    rootThirdViewContentRoot: {
      width: '100%',
    },
    rootProductThirdContainer: {
      width: '100%',
      position: 'relative',
      '& $rootProductPriceContainer': {
        justifyContent: 'flex-start',
        marginTop: '20px',
        alignItems: 'start',
        flexDirection: withLeftAlign ? 'row' : 'row-reverse',
      },
      '& $rootProductName': {
        textAlign: withLeftAlign ? 'left' : 'right',
      },
      '& $rootProductPrice': {
        alignItems: withLeftAlign ? 'start' : 'flex-end',
        marginTop: '0px',
      },
      '& $rootProductPriceOld': {
        display: 'none',
      },
      '& $rootProductPriceNew': {
        fontSize: '24px',
      },
      '& $rootFloatingProductPriceOld': {
        display: theme[product][oldPriceActive] ? 'block' : 'none',
        marginTop: '-2px',
      },
    },
    rootProductThird: {},
    rootProductMobileThird: {},
    pricingPlanWrapperMobile: {
      display: 'none',
      '& .tooltip-more__close': {
        zIndex: ORDER_BUMPS_Z_INDEXES.tooltipBackdrop,
      },
    },
    pricingPlanWrapperDesktop: {
      display: 'flex',
      '& .tooltip-more__close': {
        zIndex: ORDER_BUMPS_Z_INDEXES.tooltipBackdrop,
      },
    },
    rootProductDetailsContainerThird: {
      position: 'relative',
      width: '100%',
      '& $pricingPlanWrapperMobile': {
        display: 'none',
      },
      '& $pricingPlanWrapperDesktop': {
        display: 'flex',
        '& $rootChangePaymentPlanButton': {
          left: withLeftAlign ? 'initial' : '0px',
          right: withLeftAlign ? '0px' : 'initial',
        },
      },
      '& $rootFloatingProductPriceOld': {
        padding: withLeftAlign ? '0px 0px 0px 5px' : '0px 5px 0px 0px',
      },
    },
    rootProductPriceContainerMobileThird: {
      '&$pricingPlanWrapperMobile': {
        display: 'flex',
        justifyContent: withLeftAlign ? 'flex-start' : 'flex-end',

        '& $rootChangePaymentPlanButton': {
          position: 'initial',
          marginTop: '10px',
        },
      },
      '& $pricingPlanWrapperDesktop': {
        display: ['none', '!important'],
      },
    },
    rootProductNameThird: {
      fontSize: '22px',
    },
    rootProductDescriptionThird: {
      textAlign: 'left',
      marginTop: '10px',
    },
    rootButtonThird: {
      height: '60px',
      justifyContent: 'space-between',
      padding: '20px',
      '& $rootArrowsLeftMobile': {
        display: 'block',
      },
    },
    rootAdditionalThirdView: {
      maxWidth,
    },
  }
}

export const getFourthViewStyles = (theme) => {
  const withLeftAlign = ORDER_BUMPS_ALIGNMENT_TYPES.left === theme[ORDER_BUMPS_ALIGNMENT_FIELD_KEY]
  const maxWidthMobile = '315px'

  return {
    rootFourthView: {
      zIndex: ORDER_BUMPS_Z_INDEXES.newView,
      display: 'flex',
      alignItems: isDescriptionPresent(theme) ? 'flex-start' : 'center',
      width: '100%',
      flexDirection: withLeftAlign ? 'row' : 'row-reverse',
    },
    rootFourthViewImg: {
      '& $rootProductImage': {
        minWidth: '140px',
        width: '140px',
        height: '140px',
      },
    },
    productInnerImageMobileFourth: {
      display: 'none',
    },
    productOuterPriceMobileFourth: {
      display: 'none',
    },
    rootFourthViewContent: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      width: '100%',
    },
    rootProductFourthContainer: {
      width: '100%',
      '& $rootProductDescription': {
        marginTop: '10px',
      },
    },
    rootProductFourth: {
      width: '100%',
      '& $pricingPlanWrapperMobile': {
        display: ['none', '!important'],
      },
    },
    rootProductDetailsContainerFourth: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: isOnlyButtonInBump(theme) ? 0 : '10px',
      '& $rootProductPrice': {
        marginTop: '0px',
        alignItems: withLeftAlign ? 'center' : 'flex-start',
      },
      '& $rootProductPriceNew': {
        fontSize: '24px',
      },
      '& $rootProductPriceContainer': {
        alignItems: 'flex-start',
        flexDirection: withLeftAlign ? 'row-reverse' : 'row',
      },
      '& $rootProductPriceOld': {
        padding: withLeftAlign ? '0px 5px 0px 0px' : '0px 0px 0px 5px',
      },
    },
    rootProductNameFourth: {},
    rootProductDescriptionFourth: {
      textAlign: 'left',
    },
    rootFourthViewMobile: {
      '& $rootHeader': {
        maxWidth: maxWidthMobile,
        // minWidth: maxWidthMobile,
      },
      '& $rootFourthView': {
        flexDirection: 'column',
      },
      '& $rootFourthViewImg': {
        display: 'none',
      },
      '& $rootProductDescription': {
        marginTop: '20px',
      },
      '& $productOuterPriceMobileFourth': {
        display: 'block',
        '& $rootProductPriceContainer': {
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          marginTop: '20px',
        },
        '& $rootProductPrice': {
          marginTop: '0px',
          alignItems: 'flex-start',
        },
      },
      '& $pricingPlanWrapperDesktop': {
        display: 'none',
      },
      '& $rootProductPriceOld': {
        padding: withLeftAlign ? '0px 0px 0px 5px' : '0px 0px 0px 5px',
      },
    },
    pricingPlanWrapperMobile: {
      display: 'none',
      '& .tooltip-more__close': {
        zIndex: ORDER_BUMPS_Z_INDEXES.tooltipBackdrop,
      },
    },
    pricingPlanWrapperDesktop: {
      display: 'flex',
      '& .tooltip-more__close': {
        zIndex: ORDER_BUMPS_Z_INDEXES.tooltipBackdrop,
      },
      '& $rootChangePaymentPlanButton': {
        position: 'initial',
      },
    },
    rootProductPriceContainerFourth: {},
    rootFourthHeaderWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: theme.isHeaderProvided ? 'space-between' : 'flex-end',
      alignItems: 'center',
      width: '100%',

      '& $rootHeader': {
        maxWidth: '100%',
        // minWidth: theme.showSelectPricingPlanButton ? '480px' : '680px',
      },
    },
    rootProductPriceContainerMobileFourth: {
      '&$pricingPlanWrapperMobile': {
        display: 'flex',

        '& $rootChangePaymentPlanButton': {
          position: 'initial',
          marginTop: '10px',
        },
      },
    },
    rootProductMobileFourth: {
      display: 'flex',
      flexDirection: withLeftAlign ? 'row' : 'row-reverse',
      marginTop: isOnlyButtonInBump(theme) ? 0 : '15px',
      '& $productInnerImageMobileFourth': {
        display: 'block',
      },
      '& $rootProductDetailsContainerFourth': {
        marginTop: '0px',
        '& $rootProductPriceContainer': {
          display: 'none',
        },
      },
    },
  }
}

export const getFifthViewStyles = (theme) => {
  const withLeftAlign = ORDER_BUMPS_ALIGNMENT_TYPES.left === theme[ORDER_BUMPS_ALIGNMENT_FIELD_KEY]
  const productBlockActive =
    theme[product][productImageState] === ORDER_BUMPS_PRODUCT_IMAGE_KEYS.display ||
    theme[product][nameActive] ||
    isDescriptionPresent(theme)
  const headerActive = isHeaderActive(theme)

  return {
    rootFifthView: {
      zIndex: ORDER_BUMPS_Z_INDEXES.newView,
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: theme.isEventInfoProvided || isDescriptionPresent(theme) ? 'flex-start' : 'center',
      flexDirection: withLeftAlign ? 'row' : 'row-reverse',
      marginBottom: '0px',
      '& $rootProduct': {
        alignItems: theme.isEventInfoProvided || isDescriptionPresent(theme) ? 'flex-start' : 'center',
        '& $rootEvent': {
          marginTop: theme[product][nameActive] || isDescriptionPresent(theme) ? '25px' : '0px',
        },
      },
    },
    rootProductDescriptionMobile: {
      display: 'none',
    },
    rootFifthLeft: {
      '& $rootHeader': {
        width: '100%',
      },
      '& $rootProduct': {
        margin: headerActive ? '15px 0 0' : '0',
        justifyContent: 'flex-start',
        display: productBlockActive ? 'flex' : 'none',
      },
      '& $rootProductName': {
        lineHeight: 1,
      },
      '& $rootProductDetailsContainer': {
        alignItems: 'flex-start',
      },
      '& $rootProductDescription': {
        textAlign: 'left',
      },
    },
    rootFifthRight: {
      margin: withLeftAlign
        ? `0 0 ${theme.showSelectPricingPlanButton ? '40px' : '0px'} 30px`
        : `0 30px ${theme.showSelectPricingPlanButton ? '40px' : '0px'} 0`,
      position: 'relative',
      '& $rootProductPriceContainer': {
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: withLeftAlign ? 'flex-end' : 'flex-start',
      },
      '& $rootProductPrice': {
        margin: '0px',
        alignItems: 'baseline',
        flexDirection: withLeftAlign ? 'row' : 'row-reverse',
        justifyContent: withLeftAlign ? 'right' : 'center',
      },
      '& $rootProductPriceNew': {
        fontSize: '18px',
        lineHeight: 1,
        margin: withLeftAlign ? '0 0 0 5px' : '0 5px 0 0',
      },
      '& $rootProductPriceType': {
        fontSize: '11px',
        lineHeight: 1,
      },
    },
    fifthButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      ...getCustomFilledButtonAnimation(theme),
    },
    rootButtonFifth: {
      height: '60px',
      marginTop: '0px',
      minWidth: '240px',
    },
    pricingPlanWrapperMobile: {
      display: 'none',
      justifyContent: withLeftAlign ? 'flex-start' : 'flex-end',
      '& .tooltip-more__close': {
        zIndex: ORDER_BUMPS_Z_INDEXES.tooltipBackdrop,
      },
      '& $rootChangePaymentPlanButton': {
        position: 'initial',
        marginTop: '10px',
      },
    },
    pricingPlanWrapperDesktop: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& .tooltip-more__close': {
        zIndex: ORDER_BUMPS_Z_INDEXES.tooltipBackdrop,
      },
      '& $rootChangePaymentPlanButton': {
        position: 'absolute',
        marginTop: '10px',
        minWidth: '240px',
        left: withLeftAlign ? 'initial' : '0px',
        right: withLeftAlign ? '0px' : 'initial',
      },
    },
    rootFifthViewMobile: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: '0px',
      '& $rootProductDescriptionMobile': {
        display: isDescriptionPresent(theme) ? 'block' : 'none',
        marginTop: '20px',
      },
      '& $rootProductImage': {
        marginRight: !withLeftAlign ? '0px' : '15px',
      },
      '& $pricingPlanWrapperDesktop': {
        display: 'none',
      },
      '& $pricingPlanWrapperMobile': {
        display: 'flex',
      },
      '& $rootFifthRight': {
        margin: '0px',
        width: '100%',
        '& $rootProductPriceContainer': {
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: withLeftAlign ? 'flex-start' : 'flex-end',
          marginTop: '20px',
        },
        '& $rootProductPrice': {
          margin: '0px',
          alignItems: 'flex-start',
          flexDirection: !withLeftAlign ? 'row' : 'row-reverse',
          justifyContent: !withLeftAlign ? 'right' : 'center',
        },
        '& $rootProductPriceNew': {
          fontSize: '30px',
          lineHeight: 1,
          margin: !withLeftAlign ? '0 0 0 5px' : '0 5px 0 0',
        },
        '& $rootProductPriceType': {
          fontSize: '13px',
          lineHeight: 1,
        },
        '& $rootProduct': {
          alignItems: 'center',
        },
      },
      '& $rootFifthLeft': {
        '& $rootHeader': {
          // minWidth: maxWidthMobile,
        },
        '& $rootProduct': {
          margin: headerActive ? '15px 0 0' : '0',
          justifyContent: 'flex-start',
          display: productBlockActive ? 'flex' : 'none',
          alignItems: 'center',
        },
        '& $rootProductName': {
          lineHeight: 1,
        },
        '& $rootProductDetailsContainer': {
          alignItems: 'flex-start',
        },
        '& $rootProductDescription': {
          textAlign: 'left',
        },
      },
    },
  }
}

export const getSevenViewStyles = (theme) => {
  const withLeftAlign = ORDER_BUMPS_ALIGNMENT_TYPES.left === theme[ORDER_BUMPS_ALIGNMENT_FIELD_KEY]
  const productBlockActive =
    theme[product][productImageState] === ORDER_BUMPS_PRODUCT_IMAGE_KEYS.display ||
    theme[product][nameActive] ||
    isDescriptionPresent(theme)

  return {
    rootSevenView: {
      zIndex: ORDER_BUMPS_Z_INDEXES.newView,
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'flex-start', // (theme.isEventInfoProvided || isDescriptionPresent(theme)) ? 'flex-start' : 'center',
      flexDirection: withLeftAlign ? 'row' : 'row-reverse',
      marginBottom: '0px',
      '& $rootProduct': {
        // flexDirection: 'column',
        alignItems: theme.isEventInfoProvided || isDescriptionPresent(theme) ? 'flex-start' : 'center',
        '& $rootEvent': {
          marginTop: theme[product][nameActive] || isDescriptionPresent(theme) ? '25px' : '0px',
        },
      },
      '& $rootCheckboxImportance': {
        alignSelf: 'baseline',
      },
    },
    rootProductImageContainer: {
      marginRight: theme.isEventInfoProvided || isDescriptionPresent(theme) ? '10px' : '0',

      '& $rootProductImage': {
        minWidth: 145,
        maxWidth: 145,
        height: 145,
        marginLeft: 0,
        objectFit: 'cover',
      },
    },
    rootProductDescriptionMobile: {
      display: 'none',
    },
    rootSevenLeft: {
      display: 'flex',
      flexDirection: 'column',

      '& $rootHeader': {
        width: '100%',
      },
      '& $rootProduct': {
        fontFamily: 'Montserrat Medium',
        margin: '0',
        justifyContent: 'flex-start',
        display: productBlockActive ? 'flex' : 'none',
      },
      '& $rootProductName': {
        fontFamily: 'Montserrat Reg',
        fontWeight: 900,
        lineHeight: 1,
      },
      '& $rootProductDetailsContainer': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
      },
      '& $rootProductDescription': {
        marginTop: 8,
        textAlign: 'left',
      },
      '& .checkbox-field': {
        marginRight: 10,
      },
    },
    rootProductContainer: {
      display: 'flex',
      marginBottom: 20,
    },
    rootSevenRight: {
      margin: withLeftAlign ? `0 0 0 30px` : `0 30px 0 0`,
      position: 'relative',
      '& $rootProductPriceContainer': {
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: withLeftAlign ? 'flex-end' : 'flex-start',
      },
      '& $rootProductPrice': {
        margin: '0px',
        alignItems: 'baseline',
        flexDirection: withLeftAlign ? 'row' : 'row-reverse',
        justifyContent: withLeftAlign ? 'right' : 'center',
      },
      '& $rootProductPriceNew': {
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: 1,
        margin: withLeftAlign ? '0 0 0 5px' : '0 5px 0 0',
      },
      '& $rootProductPriceType': {
        fontSize: '11px',
        lineHeight: 1,
      },
    },
    pricingPlanWrapperMobile: {
      display: 'none',
      justifyContent: withLeftAlign ? 'flex-start' : 'flex-end',
      '& .tooltip-more__close': {
        zIndex: ORDER_BUMPS_Z_INDEXES.tooltipBackdrop,
      },
      '& $rootChangePaymentPlanButton': {
        position: 'initial',
        marginTop: '10px',
      },
    },
    pricingPlanWrapperDesktop: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& .tooltip-more__close': {
        zIndex: ORDER_BUMPS_Z_INDEXES.tooltipBackdrop,
      },
      '& $rootChangePaymentPlanButton': {
        position: 'absolute',
        marginTop: '10px',
        minWidth: '240px',
        left: withLeftAlign ? 'initial' : '0px',
        right: withLeftAlign ? '0px' : 'initial',
      },
    },
    rootSevenViewMobile: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexDirection: 'column',
      marginBottom: '0px',
      '& $rootProductDescriptionMobile': {
        display: isDescriptionPresent(theme) ? 'block' : 'none',
        marginTop: '25px',
      },
      '& $rootProductImage': {
        marginRight: 0,
      },
      '& $pricingPlanWrapperDesktop': {
        display: 'none',
      },
      '& $pricingPlanWrapperMobile': {
        display: 'flex',
      },
      '& $rootProductImageContainer': {
        marginRight: 0,
      },
      '& $rootSevenRight': {
        margin: '0px',
        width: '100%',
        '& $rootProductPriceContainer': {
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: withLeftAlign ? 'flex-start' : 'flex-end',
          marginBottom: '20px',
        },
        '& $rootProductPrice': {
          margin: '0px',
          alignItems: 'flex-start',
          flexDirection: !withLeftAlign ? 'row' : 'row-reverse',
          justifyContent: !withLeftAlign ? 'right' : 'center',
        },
        '& $rootProductPriceNew': {
          fontSize: '24px',
          lineHeight: 1,
          margin: !withLeftAlign ? '5px 0 0 5px' : '5px 5px 0 0',
        },
        '& $rootProductPriceType': {
          fontSize: '13px',
          lineHeight: 1,
        },
        '& $rootProduct': {
          alignItems: 'center',
        },
      },
      '& $rootSevenLeft': {
        width: '100%',
        '& $rootProduct': {
          margin: '0',
          justifyContent: 'center',
          display: productBlockActive ? 'flex' : 'none',
          alignItems: 'center',
        },
        '& $rootProductName': {
          fontSize: '18px',
          lineHeight: 1,
          marginTop: 5,
        },
        '& $rootProductDetailsContainer': {
          alignItems: 'flex-start',
        },
        '& $rootProductDescription': {
          textAlign: 'left',
        },
      },
    },
    sevenHorisontalSeparator: {
      position: 'absolute',
      backgroundColor: '#eaeaea',
      width: 'calc(100% - 60px)',
      height: '1px',
      bottom: 0,
      zIndex: 1,
    },
  }
}
