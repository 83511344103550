import { COLORS } from '@elo-kit/constants/general.constants'

import { isWindows } from 'utils/browsers.utils'
import { getFontStyles } from '@elo-kit/utils/fonts.utils'
import { cleanupFonts } from 'utils/fonts.utils'

const { eloBlack, eloBlue, eloWhite } = COLORS

// TODO: update with using createUseStyles
const bundleStyles = (theme) => ({
  wrapper: {
    borderRadius: '4px',
    boxShadow: '0 1px 8px 0 rgba(0, 0, 0, 0.13)',
    backgroundColor: eloWhite,
  },
  label: {
    padding: theme.isMobilePreview ? '5px 15px' : '14px 44px',
    fontFamily: cleanupFonts(theme.labelFontFamily) || 'Open Sans',
    ...getFontStyles(theme.labelFontStyle || 'bold'),
    color: theme.labelColor || eloWhite,
    fontSize: `${theme.labelSize || '14'}px`,
    backgroundColor: theme.labelBgColor || eloBlue,
    borderRadius: '4px 4px 0px 0px',
    '@media screen and (max-width: 991.98px)': {
      padding: '5px 15px',
    },
  },
  radioField: {
    marginBottom: 0,
    '&:hover': {
      '& .custom-check-mark': {
        '&:before': {
          backgroundColor: ['#D5D5D5', '!important'],
        },
      },
      '& .radio-field__control--active': {
        '& .custom-check-mark': {
          '&:before': {
            backgroundColor: [eloWhite, '!important'],
          },
        },
      },
    },
    '& .custom-check-mark': {
      '&:before': {
        backgroundColor: [eloWhite, '!important'],
      },
    },
  },
  wrapperCursor: {
    cursor: 'pointer',
  },
  innerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: theme.isMobilePreview ? 'column' : 'row',
    padding: theme.isMobilePreview ? '25px 20px' : '30px',
    '@media screen and (max-width: 991.98px)': {
      flexDirection: 'column',
      padding: '25px 20px',
    },
    border: theme.label ? `1px solid ${theme.labelBgColor || eloBlue}` : 'none',
  },
  productInfoTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  productInfoPrice: {
    fontSize: 20,
    color: '#545454',
    fontFamily: 'Montserrat Medium',
    lineHeight: 1,
    flexShrink: 0,
    textAlign: 'right',
  },
  couponApplied: {
    fontFamily: 'Montserrat Reg',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#39AA75',
    textAlign: 'right',
    marginTop: 5,

    '& i': {
      marginRight: 5,
    },
  },
  priceCrossed: {
    position: 'relative',
    '&:before': {
      content: "''",
      height: 1,
      position: 'absolute',
      width: '100%',
      left: 0,
      top: 8,
      background: 'rgb(84, 84, 84)',
    },
  },
  oldPrice: {
    fontFamily: 'Montserrat Reg',
    position: 'relative',
    overflow: 'hidden',
    fontSize: 13,
    color: '#cc3a3a',
    marginRight: 10,
    '&:before': {
      position: 'absolute',
      content: '""',
      background: 'red',
      display: 'block',
      width: '100%',
      height: 1,
      left: 0,
      right: 0,
      top: 0,
      bottom: isWindows ? 0 : 2,
      margin: 'auto',
    },
  },
  productInfoBordered: {
    paddingRight: 30,
    borderRight: theme.isMobilePreview ? 'none' : 'solid 1px rgba(151, 151, 151, 0.2)',
    flex: theme.isMobilePreview ? 1 : 0.72,
    '@media screen and (max-width: 991.98px)': {
      flex: 1,
      border: 'none',
    },
  },
  productInfoFullSize: {
    flex: 1,
  },
  pricingPlans: {
    flex: theme.isMobilePreview ? 1 : 0.35,
    paddingLeft: theme.isMobilePreview ? 0 : 15,
    paddingTop: theme.isMobilePreview ? 30 : 0,
    margin: theme.isMobilePreview ? '0px -10px' : 'initial',
    '@media screen and (max-width: 991.98px)': {
      flex: 1,
      paddingLeft: 0,
      paddingTop: 30,
      margin: '0px -10px',
    },
  },
  countInputContainer: {
    backgroundColor: '#e1e1e1',
    borderRadius: '0 0 4px 4px',
    padding: theme.isMobilePreview ? '5px 20px' : '10px 30px',
    display: 'flex',
    alignItems: 'center',
    '@media screen and (max-width: 991.98px)': {
      padding: '5px 20px',
    },
  },
  productUpsell: {
    '&:not(:first-child)': {
      marginTop: 15,
    },
    borderRadius: 6,
  },
  productUpsellActive: {
    borderColor: theme.labelBgColor || eloBlue,
  },
  productInfoTitle: {
    fontSize: 18,
    fontFamily: 'Montserrat Medium',
    color: eloBlack,
    lineHeight: 1,
  },
  productList: {
    marginTop: 20,
  },
  productItem: {
    display: 'flex',
    '&:not(:first-child)': {
      marginTop: 15,
    },
  },
  productNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  productName: {
    color: eloBlack,
    fontSize: 16,
    fontFamily: 'Montserrat Medium',
    lineHeight: 1,
    margin: '5px 0px 5px 0px',
  },
  ticketsCountBadge: {
    display: 'inline-block',
    boxSizing: 'content-box',
    position: 'relative',
    backgroundSize: '51% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundImage:
      'radial-gradient(circle at 0 50%, rgba(166,170,179,0) 0.36em, #cdcdcd 0.4em),radial-gradient(circle at 100% 50%, rgba(166,170,179,0) 0.36em, #cdcdcd 0.4em)',
    backgroundPosition: 'top left, top right',
    borderRadius: 2,
    fontSize: 10,
    color: eloWhite,
    padding: '4px 5px',
    maxWidth: 76,
    lineHeight: 1.3,
    textAlign: 'center',
  },
  ticketDetailsText: {
    fontSize: 12,
    color: '#9a9a9a',
    paddingBottom: 2,
    display: 'flex',
    alignItems: 'center',
    '.fas': {
      marginRight: 5,
      color: '#d2d2d2',
    },
  },
  ticketDetailsIcon: {
    marginRight: 5,
    color: '#d2d2d2',
    fontSize: 14,
  },
  productDescription: {
    marginTop: 20,
    color: '#787878',
    fontSize: '14px',
    fontFamily: 'Montserrat Reg',
    lineHeight: '1.14',
  },
  addUpsellButton: {
    height: 44,
    borderRadius: 22,
    width: 208,
    marginTop: 20,
    fontFamily: cleanupFonts(theme.buyButtonFontFamily) || 'Open Sans',
    ...getFontStyles(theme.buyButtonFontStyle || 'bold'),
    color: theme.buyButtonFontColor || eloWhite,
    fontSize: `${theme.buyButtonFontSize || '14'}px`,
    backgroundColor: theme.buyButtonColor || eloBlue,
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
})

export default bundleStyles
