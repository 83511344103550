import React from 'react'

const ClassicArrows = () => (
  <svg
    width='50px'
    height='25px'
    viewBox='0 0 50 25'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g id='Page-1' stroke='none' strokeWidth='1' fillRule='evenodd'>
      <polygon
        id='7'
        points='32.6851576 0 32.6851576 7.30612865 0.171875 3.70372166 0.171875 21.2961168 32.6851576 17.6937098 32.6851576 25 49.3442623 12.5'
      />
    </g>
  </svg>
)

export default ClassicArrows
