import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { observer } from 'mobx-react'

import { getProductTypeIconClasses, getFirstCoverUrl } from 'utils/product.utils'

import { PRODUCT_TYPES } from '@elo-ui/types'
import { PRODUCT_TITLE } from 'constants/products.constants'

import { EloTooltip } from '@elo-ui/components/elo-tooltip'
import {
  EloCertificateIcon,
  EloCheckoutIcon,
  EloDownloadIcon,
  EloMembershipIcon,
  EloOnlineCourseIcon,
  EloTicketIcon,
  EloLicenseCodeIcon,
  EloBundleIcon,
} from '@elo-ui/components/icons/product-types'

const propTypes = {
  product: PropTypes.shape({}),
  size: PropTypes.string,
  darkBackground: PropTypes.bool,
  showProductForm: PropTypes.bool,
}

const defaultProps = {
  darkBackground: false,
  showProductForm: true,
}

@observer
class ProductCover extends Component {
  render() {
    const {
      product = {},
      darkBackground,
      size,
      noIconBackground,
      showProductFormIcon,
      showProductIcon,
      showTooltip,
    } = this.props

    const productTypeIconClasses = getProductTypeIconClasses(product.form)

    const coverImgClasses = classNames('cover__img', {
      'cover__img--not-published': !product.active,
      'cover__img--big': size === 'big',
      'cover__img--extra-big': size === 'extra-big',
    })

    const coverCenterIconClasses = classNames('cover__center-icon', {
      'cover__center-icon--disabled': !product.private && product.active,
      'cover__center-icon--not-published': !product.active,
      'cover__center-icon--private': product.private,
    })

    const coverRightIconClasses = classNames(
      {
        'cover__product-type-icon-disabled': noIconBackground,
        'cover__product-type-icon': !noIconBackground,
        'cover__product-type-icon-disabled--dark-background': noIconBackground && darkBackground,
        'cover__product-type-icon--dark-background ': darkBackground,
        'cover__product-type-icon--big': size === 'big',
        'cover__product-type-icon--extra-big': size === 'extra-big',
      },
      productTypeIconClasses && productTypeIconClasses.container && !noIconBackground
    )

    const wrapperClasses = classNames('product-details__cover cover', {
      'cover--big': size === 'big',
      'cover--extra-big': size === 'extra-big',
    })
    const noCoverClasses = classNames('cover__no-cover', {
      'cover__no-cover--big': size === 'big',
      'cover__no-cover--extra-big': size === 'extra-big',
    })

    const coverUrl = product.covers?.[0]?.file?.custom || product.cover || getFirstCoverUrl(product)

    const productIcons = {
      [PRODUCT_TYPES.digital]: EloCheckoutIcon,
      [PRODUCT_TYPES.download]: EloDownloadIcon,
      [PRODUCT_TYPES.course]: EloOnlineCourseIcon,
      [PRODUCT_TYPES.eventTickets]: EloTicketIcon,
      [PRODUCT_TYPES.membership]: EloMembershipIcon,
      [PRODUCT_TYPES.bundle]: EloBundleIcon,
      [PRODUCT_TYPES.licenseKey]: EloLicenseCodeIcon,
      [PRODUCT_TYPES.certificate]: EloCertificateIcon,
    }

    const Icon = productIcons[product.form]

    return (
      <div className={wrapperClasses}>
        {!!coverUrl && (
          <Fragment>
            <img src={coverUrl} className={coverImgClasses} />
            <div className={coverCenterIconClasses}>
              {product.private ? (
                <i className='fas fa-user-secret' />
              ) : (
                !product.active && <i className='far fa-eye-slash' />
              )}
            </div>
          </Fragment>
        )}
        {!coverUrl && (
          <div className={noCoverClasses}>
            {product.private && <i className='fas fa-user-secret' />}
            {!product.private && product.active && <i className='fas fa-camera' />}
            {!product.private && !product.active && <i className='fas fa-eye-slash' />}
          </div>
        )}
        {product.form && showProductFormIcon && (
          <div className={coverRightIconClasses}>
            <i className={productTypeIconClasses && productTypeIconClasses.icon} />
          </div>
        )}
        {product.form && showProductIcon && (
          <div className={coverRightIconClasses}>
            {showTooltip ? (
              <EloTooltip
                placement='right'
                title={I18n.t('react.cabinet.product.form.product_type')}
                content={PRODUCT_TITLE[product.form]}
              >
                <Icon width={24} height={24} />
              </EloTooltip>
            ) : (
              <Icon width={24} height={24} />
            )}
          </div>
        )}
      </div>
    )
  }
}

ProductCover.displayName = 'ProductCover'
ProductCover.propTypes = propTypes
ProductCover.defaultProps = defaultProps

export default ProductCover
