import { BUTTON_STYLE } from '@elo-kit/constants/contentPage.constants'

import imgOne from 'images/order_bumps/1.svg'
import imgTwo from 'images/order_bumps/2.svg'
import imgThee from 'images/order_bumps/3.svg'
import imgFour from 'images/order_bumps/4.svg'
import imgFive from 'images/order_bumps/5.svg'
import imgSix from 'images/order_bumps/6.svg'
import imgSeven from 'images/order_bumps/7.svg'

import { ORDER_BUMPS_PREVIEW_TYPES } from './orderBumpsShared.constants'

export * from './orderBumpsShared.constants'

export const ORDER_BUMPS_FONT_SIZE_MAX_VALUE = 35
export const ORDER_BUMPS_FONT_SIZE_MAX_VALUE_SLIM = 26

export const ORDER_BUMPS_VIEW_FIELD_KEY = 'viewType'

export const ORDER_BUMPS_VIEW_TYPES_KEYS = {
  first: 'first',
  sec: 'sec',
  third: 'third',
  fourth: 'fourth',
  fifth: 'fifth',
  six: 'six',
  seven: 'seven',
}

export const ORDER_BUMPS_Z_INDEXES = {
  background: 1,
  backgroundImage: 2,
  header: 3,
  button: 2,
  additionalText: 3,
  product: 3,
  newView: 3,
  tooltipBackdrop: 10,
}

export const PREVIEW_ALLOWED_VIEWS = [
  ORDER_BUMPS_VIEW_TYPES_KEYS.first,
  ORDER_BUMPS_VIEW_TYPES_KEYS.sec,
  ORDER_BUMPS_VIEW_TYPES_KEYS.third,
  ORDER_BUMPS_VIEW_TYPES_KEYS.fourth,
  ORDER_BUMPS_VIEW_TYPES_KEYS.fifth,
  ORDER_BUMPS_VIEW_TYPES_KEYS.six,
  ORDER_BUMPS_VIEW_TYPES_KEYS.seven,
]

export const ALIGNMENT_ALLOWED_VIEWS = [
  ORDER_BUMPS_VIEW_TYPES_KEYS.first,
  ORDER_BUMPS_VIEW_TYPES_KEYS.sec,
  ORDER_BUMPS_VIEW_TYPES_KEYS.fourth,
  ORDER_BUMPS_VIEW_TYPES_KEYS.third,
  ORDER_BUMPS_VIEW_TYPES_KEYS.fifth,
  ORDER_BUMPS_VIEW_TYPES_KEYS.seven,
]

export const ORDER_BUMPS_VIEW_TYPES = [
  {
    id: ORDER_BUMPS_VIEW_TYPES_KEYS.first,
    label: I18n.t('react.cabinet.order_bumps.view.first'),
    imgSrc: imgOne,
  },
  {
    id: ORDER_BUMPS_VIEW_TYPES_KEYS.sec,
    label: I18n.t('react.cabinet.order_bumps.view.sec'),
    imgSrc: imgTwo,
  },
  {
    id: ORDER_BUMPS_VIEW_TYPES_KEYS.third,
    label: I18n.t('react.cabinet.order_bumps.view.third'),
    imgSrc: imgThee,
  },
  {
    id: ORDER_BUMPS_VIEW_TYPES_KEYS.fourth,
    label: I18n.t('react.cabinet.order_bumps.view.fourth'),
    imgSrc: imgFour,
  },
  {
    id: ORDER_BUMPS_VIEW_TYPES_KEYS.fifth,
    label: I18n.t('react.cabinet.order_bumps.view.fifth'),
    imgSrc: imgFive,
  },
  {
    id: ORDER_BUMPS_VIEW_TYPES_KEYS.six,
    label: I18n.t('react.cabinet.order_bumps.view.six'),
    imgSrc: imgSix,
  },
  {
    id: ORDER_BUMPS_VIEW_TYPES_KEYS.seven,
    label: I18n.t('react.cabinet.order_bumps.view.seven'),
    imgSrc: imgSeven,
  },
]

export const ORDER_BUMPS_OLD_VIEW_KEY = 'bundleView'

export const ORDER_BUMPS_TABS_KEYS = {
  product: 'product',
  header: 'header',
  description: 'description',
  background: 'background',
  button: 'button',
  arrows: 'arrows',
  animation: 'animation',
  additionalText: 'additionalText',
}

export const ORDER_BUMPS_FIELDS_KEYS = {
  [ORDER_BUMPS_TABS_KEYS.product]: {
    nameColor: 'nameColor',
    descriptionColor: 'descriptionColor',
    priceColor: 'priceColor',
    oldPriceColor: 'oldPriceColor',
    nameActive: 'nameActive',
    descriptionActive: 'descriptionActive',
    priceActive: 'priceActive',
    oldPriceActive: 'oldPriceActive',
    productImageState: 'productImageState',
  },
  [ORDER_BUMPS_TABS_KEYS.header]: {
    headerText: 'headerText',
    headerFontFamily: 'headerFontFamily',
    headerFontStyle: 'headerFontStyle',
    headerColor: 'headerColor',
    headerFontSize: 'headerFontSize',
  },
  [ORDER_BUMPS_TABS_KEYS.description]: {
    productDescriptionActive: 'productDescriptionActive',
    productDescription: 'productDescription',
  },
  [ORDER_BUMPS_TABS_KEYS.background]: {
    backgroundColor: 'backgroundColor',
    backgroundShadow: 'backgroundShadow',
    backgroundBorder: 'backgroundBorder',
    backgroundTransparency: 'backgroundTransparency',
    backgroundBorderColor: 'backgroundBorderColor',
    backgroundImage: 'backgroundImage',
    backgroundFilename: 'backgroundFilename',
    backgroundFileId: 'backgroundFileId',
  },
  [ORDER_BUMPS_TABS_KEYS.button]: {
    buttonText: 'buttonText',
    buttonFontFamily: 'buttonFontFamily',
    buttonFontStyle: 'buttonFontStyle',
    buttonColor: 'buttonColor',
    buttonRadius: 'buttonRadius',
    buttonButtonColor: 'buttonButtonColor',
    buttonFontSize: 'buttonFontSize',
    buttonViewStyle: 'buttonViewStyle',
    buttonCheckboxColor: 'buttonCheckboxColor',
    buttonCheckboxBorder: 'buttonCheckboxBorder',
    buttonCheckboxStyle: 'buttonCheckboxStyle',
  },
  [ORDER_BUMPS_TABS_KEYS.arrows]: {
    arrowsColor: 'arrowsColor',
    arrowsStyle: 'arrowsStyle',
    arrowsTransparency: 'arrowsTransparency',
  },
  [ORDER_BUMPS_TABS_KEYS.animation]: {
    animationButton: 'animationButton',
    animationArrows: 'animationArrows',
    animationBorder: 'animationBorder',
    animationHeader: 'animationHeader',
    animationPrice: 'animationPrice',
  },
  [ORDER_BUMPS_TABS_KEYS.additionalText]: {
    additionalTextActive: 'additionalTextActive',
    additionalTextText: 'additionalTextText',
    additionalTextRadius: 'additionalTextRadius',
    additionalTextTransparency: 'additionalTextTransparency',
    additionalTextBackgroundColor: 'additionalTextBackgroundColor',
  },
  [ORDER_BUMPS_OLD_VIEW_KEY]: {
    labelEnabled: 'labelEnabled',
    labelFontFamily: 'labelFontFamily',
    labelFontStyle: 'labelFontStyle',
    labelColor: 'labelColor',
    labelSize: 'labelSize',
    labelBgColor: 'labelBgColor',
    label: 'label',
    buyButtonEnabled: 'buyButtonEnabled',
    buyButtonFontFamily: 'buyButtonFontFamily',
    buyButtonFontStyle: 'buyButtonFontStyle',
    buyButtonFontColor: 'buyButtonFontColor',
    buyButtonFontSize: 'buyButtonFontSize',
    buyButtonColor: 'buyButtonColor',
    buttonText: 'buttonText',
    showProductNames: 'showProductNames',
  },
}

export const ORDER_BUMPS_TABS = [
  {
    key: ORDER_BUMPS_TABS_KEYS.product,
    label: I18n.t('react.cabinet.order_bumps.tabs.product'),
  },
  {
    key: ORDER_BUMPS_TABS_KEYS.header,
    label: I18n.t('react.cabinet.order_bumps.tabs.header'),
  },
  {
    key: ORDER_BUMPS_TABS_KEYS.description,
    label: I18n.t('react.cabinet.order_bumps.tabs.description'),
  },
  {
    key: ORDER_BUMPS_TABS_KEYS.background,
    label: I18n.t('react.cabinet.order_bumps.tabs.background'),
  },
  {
    key: ORDER_BUMPS_TABS_KEYS.button,
    label: I18n.t('react.cabinet.order_bumps.tabs.button'),
  },
  {
    key: ORDER_BUMPS_TABS_KEYS.arrows,
    label: I18n.t('react.cabinet.order_bumps.tabs.arrows'),
  },
  {
    key: ORDER_BUMPS_TABS_KEYS.animation,
    label: I18n.t('react.cabinet.order_bumps.tabs.animation'),
  },
  {
    key: ORDER_BUMPS_TABS_KEYS.additionalText,
    label: I18n.t('react.cabinet.order_bumps.tabs.additional_text'),
  },
]

export const ORDER_BUMPS_SEVEN_TABS = [
  {
    key: ORDER_BUMPS_TABS_KEYS.product,
    label: I18n.t('react.cabinet.order_bumps.tabs.product'),
  },
  {
    key: ORDER_BUMPS_TABS_KEYS.background,
    label: I18n.t('react.cabinet.order_bumps.tabs.background'),
  },
]

export const ORDER_BUMPS_PRODUCT_IMAGE_KEYS = {
  display: 'display',
  hide: 'hide',
}

export const ORDER_BUMPS_PRODUCT_IMAGE_STATE = [
  {
    value: ORDER_BUMPS_PRODUCT_IMAGE_KEYS.display,
    label: I18n.t('react.cabinet.order_bumps.tabs_product.image.state.display'),
  },
  {
    value: ORDER_BUMPS_PRODUCT_IMAGE_KEYS.hide,
    label: I18n.t('react.cabinet.order_bumps.tabs_product.image.state.none'),
  },
]

export const ORDER_BUMPS_SHADOW_KEYS = {
  yes: 'yes',
  no: 'no',
}

export const ORDER_BUMPS_SHADOW_OPTIONS = [
  {
    label: I18n.t('shared.common.yes'),
    value: 'yes',
  },
  {
    label: I18n.t('shared.common.no'),
    value: 'no',
  },
]

export const ORDER_BUMPS_ARROW_TYPES = {
  left: 'left',
  right: 'right',
}

export const ORDER_BUMPS_ARROW_KEYS = {
  default: 'default',
  speedUp: 'speedUp',
  dotted: 'dotted',
  repeating: 'repeating',
  long: 'long',
  waves: 'waves',
  classic: 'classic',
  classicAlt: 'classicAlt',
  no: 'no',
}

export const ORDER_BUMPS_ARROW_OPTIONS = [
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_arrows.default'),
    value: ORDER_BUMPS_ARROW_KEYS.default,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_arrows.speed_up'),
    value: ORDER_BUMPS_ARROW_KEYS.speedUp,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_arrows.dotted'),
    value: ORDER_BUMPS_ARROW_KEYS.dotted,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_arrows.repeating'),
    value: ORDER_BUMPS_ARROW_KEYS.repeating,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_arrows.long'),
    value: ORDER_BUMPS_ARROW_KEYS.long,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_arrows.waves'),
    value: ORDER_BUMPS_ARROW_KEYS.waves,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_arrows.classic'),
    value: ORDER_BUMPS_ARROW_KEYS.classic,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_arrows.classic_alt'),
    value: ORDER_BUMPS_ARROW_KEYS.classicAlt,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_arrows.no_arrows'),
    value: ORDER_BUMPS_ARROW_KEYS.no,
  },
]

export const ORDER_BUMPS_BORDER_KEYS = {
  thin: 'thin',
  medium: 'medium',
  thick: 'thick',
  no: 'no',
}

export const ORDER_BUMPS_BORDER_OPTIONS = [
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_background.thin_border'),
    value: ORDER_BUMPS_BORDER_KEYS.thin,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_background.medium_border'),
    value: ORDER_BUMPS_BORDER_KEYS.medium,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_background.thick_border'),
    value: ORDER_BUMPS_BORDER_KEYS.thick,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_background.no_border'),
    value: ORDER_BUMPS_BORDER_KEYS.no,
  },
]

export const ORDER_BUMPS_CHECKBOX_STYLE_OPTION_KEYS = {
  filled: 'filled',
  notFilled: 'notFilled',
  rounded: 'rounded',
  sharp: 'sharp',
  big: 'big',
}

export const ORDER_BUMPS_CHECKBOX_STYLE_OPTIONS = [
  {
    label: I18n.t('react.shared.checkbox_style.filled'),
    value: ORDER_BUMPS_CHECKBOX_STYLE_OPTION_KEYS.filled,
  },
  {
    label: I18n.t('react.shared.checkbox_style.not_filled'),
    value: ORDER_BUMPS_CHECKBOX_STYLE_OPTION_KEYS.notFilled,
  },
  {
    label: I18n.t('react.shared.checkbox_style.rounded'),
    value: ORDER_BUMPS_CHECKBOX_STYLE_OPTION_KEYS.rounded,
  },
  {
    label: I18n.t('react.shared.checkbox_style.sharp'),
    value: ORDER_BUMPS_CHECKBOX_STYLE_OPTION_KEYS.sharp,
  },
  {
    label: I18n.t('react.shared.checkbox_style.big'),
    value: ORDER_BUMPS_CHECKBOX_STYLE_OPTION_KEYS.big,
  },
]

export const ORDER_BUMPS_BUTTON_STYLE_OPTIONS = [
  {
    label: I18n.t('react.shared.button_style.filled'),
    value: BUTTON_STYLE.filled,
  },
  {
    label: I18n.t('react.shared.button_style.outlined'),
    value: BUTTON_STYLE.outlined,
  },
]

export const ORDER_BUMPS_ALIGNMENT_FIELD_KEY = 'alignmentType'
export const ORDER_BUMPS_ALIGNMENT_TYPES = {
  left: 'left',
  right: 'right',
}

export const ORDER_BUMPS_BUTTON_ANIMATION_KEYS = {
  shadow: 'shadow',
  transparency: 'transparency',
  coloredShadow: 'coloredShadow',
  stretching: 'stretching',
  bordering: 'bordering',
  increasing: 'increasing',
  swing: 'swing',
  fillOutlineSwitch: 'fillOutlineSwitch',
  no: 'no',
}

export const ORDER_BUMPS_BUTTON_ANIMATION_OPTIONS = [
  {
    label: I18n.t('react.shared.animation.transparency'),
    value: ORDER_BUMPS_BUTTON_ANIMATION_KEYS.transparency,
  },
  {
    label: I18n.t('react.shared.animation.shadow'),
    value: ORDER_BUMPS_BUTTON_ANIMATION_KEYS.shadow,
  },
  {
    label: I18n.t('react.shared.animation.stretching'),
    value: ORDER_BUMPS_BUTTON_ANIMATION_KEYS.stretching,
  },
  {
    label: I18n.t('react.shared.animation.bordering'),
    value: ORDER_BUMPS_BUTTON_ANIMATION_KEYS.bordering,
  },
  {
    label: I18n.t('react.shared.animation.increasing'),
    value: ORDER_BUMPS_BUTTON_ANIMATION_KEYS.increasing,
  },
  {
    label: I18n.t('react.shared.animation.swing'),
    value: ORDER_BUMPS_BUTTON_ANIMATION_KEYS.swing,
  },
  {
    label: I18n.t('react.shared.animation.fill_outline_switch'),
    value: ORDER_BUMPS_BUTTON_ANIMATION_KEYS.fillOutlineSwitch,
  },
  {
    label: I18n.t('react.shared.animation.colored_shadow'),
    value: ORDER_BUMPS_BUTTON_ANIMATION_KEYS.coloredShadow,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_animations.no_animation'),
    value: ORDER_BUMPS_BUTTON_ANIMATION_KEYS.no,
  },
]

export const ORDER_BUMPS_ARROW_ANIMATION_KEYS = {
  default: 'default',
  no: 'no',
}

export const ORDER_BUMPS_ARROWS_ANIMATION_OPTIONS = [
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_animations.arrow_default'),
    value: ORDER_BUMPS_ARROW_ANIMATION_KEYS.default,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_animations.no_animation'),
    value: ORDER_BUMPS_ARROW_ANIMATION_KEYS.no,
  },
]

export const ORDER_BUMPS_BORDER_ANIMATION_KEYS = {
  borderDanceLeft: 'borderDanceLeft',
  borderDanceRight: 'borderDanceRight',
  no: 'no',
}

export const ORDER_BUMPS_BORDER_ANIMATION_OPTIONS = [
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_animations.border_dance'),
    value: ORDER_BUMPS_BORDER_ANIMATION_KEYS.borderDanceLeft,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_animations.border_dance_r'),
    value: ORDER_BUMPS_BORDER_ANIMATION_KEYS.borderDanceRight,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_animations.no_animation'),
    value: ORDER_BUMPS_BORDER_ANIMATION_KEYS.no,
  },
]

export const ORDER_BUMPS_HEADER_ANIMATION_KEYS = {
  shiftLeft: 'shiftLeft',
  shiftRight: 'shiftRight',
  textClipLeft: 'textClipLeft',
  textClipRight: 'textClipRight',
  textClipLeftNoShadow: 'textClipLeftNoShadow',
  textClipRightNoShadow: 'textClipRightNoShadow',
  jump: 'jump',
  jumpNoShadow: 'jumpNoShadow',
  no: 'no',
}

export const ORDER_BUMPS_HEADER_ANIMATION_OPTIONS = [
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_animations.shift_l'),
    value: ORDER_BUMPS_HEADER_ANIMATION_KEYS.shiftLeft,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_animations.shift_r'),
    value: ORDER_BUMPS_HEADER_ANIMATION_KEYS.shiftRight,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_animations.jump'),
    value: ORDER_BUMPS_HEADER_ANIMATION_KEYS.jump,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_animations.jump_without_shadow'),
    value: ORDER_BUMPS_HEADER_ANIMATION_KEYS.jumpNoShadow,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_animations.textClipLeft'),
    value: ORDER_BUMPS_HEADER_ANIMATION_KEYS.textClipLeft,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_animations.textClipRight'),
    value: ORDER_BUMPS_HEADER_ANIMATION_KEYS.textClipRight,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_animations.textClipLeftNoShadow'),
    value: ORDER_BUMPS_HEADER_ANIMATION_KEYS.textClipLeftNoShadow,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_animations.textClipRightNoShadow'),
    value: ORDER_BUMPS_HEADER_ANIMATION_KEYS.textClipRightNoShadow,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_animations.no_animation'),
    value: ORDER_BUMPS_HEADER_ANIMATION_KEYS.no,
  },
]

export const ORDER_BUMPS_PRICE_ANIMATION_OPTIONS = [
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_animations.shift_l'),
    value: ORDER_BUMPS_HEADER_ANIMATION_KEYS.shiftLeft,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_animations.shift_r'),
    value: ORDER_BUMPS_HEADER_ANIMATION_KEYS.shiftRight,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_animations.jump'),
    value: ORDER_BUMPS_HEADER_ANIMATION_KEYS.jump,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_animations.jump_without_shadow'),
    value: ORDER_BUMPS_HEADER_ANIMATION_KEYS.jumpNoShadow,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_animations.textClipLeft'),
    value: ORDER_BUMPS_HEADER_ANIMATION_KEYS.textClipLeft,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_animations.textClipRight'),
    value: ORDER_BUMPS_HEADER_ANIMATION_KEYS.textClipRight,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_animations.textClipLeftNoShadow'),
    value: ORDER_BUMPS_HEADER_ANIMATION_KEYS.textClipLeftNoShadow,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_animations.textClipRightNoShadow'),
    value: ORDER_BUMPS_HEADER_ANIMATION_KEYS.textClipRightNoShadow,
  },
  {
    label: I18n.t('react.cabinet.order_bumps.tabs_animations.no_animation'),
    value: ORDER_BUMPS_HEADER_ANIMATION_KEYS.no,
  },
]

export const ORDER_BUMPS_MAX_CORNER_RADIUS = 100
export const ORDER_BUMPS_DEFAULT_SHADOW_STATE = ORDER_BUMPS_SHADOW_KEYS.yes
export const ORDER_BUMPS_DEFAULT_BORDER_STATE = ORDER_BUMPS_BORDER_KEYS.thin
export const ORDER_BUMPS_DEFAULT_BORDER_COLOR = '#31ee95'
export const ORDER_BUMPS_DEFAULT_ARROWS_ANIMATION_STATE = ORDER_BUMPS_ARROW_ANIMATION_KEYS.default
export const ORDER_BUMPS_DEFAULT_BORDER_ANIMATION_STATE = ORDER_BUMPS_BORDER_ANIMATION_KEYS.borderDanceLeft
export const ORDER_BUMPS_DEFAULT_ARROW_STATE = ORDER_BUMPS_ARROW_KEYS.default
export const ORDER_BUMPS_DEFAULT_BUTTON_STYLE = BUTTON_STYLE.filled
export const ORDER_BUMPS_DEFAULT_BUTTON_ANIMATION_STATE = ORDER_BUMPS_BUTTON_ANIMATION_KEYS.transparency
export const ORDER_BUMPS_DEFAULT_BUTTON_COLOR = '#17df87'
export const ORDER_BUMPS_DEFAULT_BUTTON_CHECKBOX_COLOR = '#ffffff'
export const ORDER_BUMPS_DEFAULT_BUTTON_RADIUS = 6
export const ORDER_BUMPS_DEFAULT_CHECKBOX_STYLE = ORDER_BUMPS_CHECKBOX_STYLE_OPTION_KEYS.filled
export const ORDER_BUMPS_DEFAULT_ADDITIOANL_TEXT_TRANSPARENCY = 1
export const ORDER_BUMPS_DEFAULT_ADDITIOANL_TEXT_RADIUS = 4
export const ORDER_BUMPS_DEFAULT_ADDITIOANL_TEXT_BG = '#f3f5f8'
export const ORDER_BUMPS_DEFAULT_BUTTON_FONT_SIZE = 18
export const ORDER_BUMPS_DEFAULT_BUNDLE_LABEL_FONT_SIZE = 18
export const ORDER_BUMPS_DEFAULT_BUNDLE_BUTTON_FONT_SIZE = 14
export const ORDER_BUMPS_DEFAULT_HEADER_FONT_SIZE = 26
export const ORDER_BUMPS_DEFAULT_HEADER_ANIMATION = ORDER_BUMPS_HEADER_ANIMATION_KEYS.textClipRight
export const ORDER_BUMPS_DEFAULT_PRICE_ANIMATION = ORDER_BUMPS_HEADER_ANIMATION_KEYS.textClipLeft
export const ORDER_BUMPS_DEFAULT_HEADER_COLOR = '#485056'
export const ORDER_BUMPS_DEFAULT_TAB = ORDER_BUMPS_TABS_KEYS.product
export const ORDER_BUMPS_DEFAULT_VIEW_TYPE = ORDER_BUMPS_VIEW_TYPES_KEYS.first
export const ORDER_BUMPS_DEFAULT_PREVIEW_STATE = ORDER_BUMPS_PREVIEW_TYPES.desktop
export const ORDER_BUMPS_DEFAULT_FONT_FAMILY = 'Montserrat Medium'
export const ORDER_BUMPS_DEFAULT_BUNDLE_FONT_FAMILY = 'Open Sans'
export const ORDER_BUMPS_DEFAULT_FONT_STYLE = 'bold'
export const ORDER_BUMPS_DEFAULT_TRANSPARENCY = 1
export const ORDER_BUMPS_DEFAULT_ARROWS_TRANSPARENCY = 1
export const ORDER_BUMPS_DEFAULT_PRODUCT_IMAGE_STATE = ORDER_BUMPS_PRODUCT_IMAGE_KEYS.display
export const ORDER_BUMPS_DEFAULT_ALIGNMENT = ORDER_BUMPS_ALIGNMENT_TYPES.left
export const ORDER_BUMPS_DEFAULT_ARROWS_COLOR = '#31ee95'
