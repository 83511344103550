import { BUTTON_STYLE } from '@elo-kit/constants/contentPage.constants'
import { COLORS } from '@elo-kit/constants/general.constants'

import {
  ORDER_BUMPS_TABS_KEYS,
  ORDER_BUMPS_FIELDS_KEYS,
  ORDER_BUMPS_BORDER_ANIMATION_KEYS,
  ORDER_BUMPS_ARROW_TYPES,
  ORDER_BUMPS_ARROW_ANIMATION_KEYS,
  ORDER_BUMPS_BUTTON_ANIMATION_KEYS,
  ORDER_BUMPS_HEADER_ANIMATION_KEYS,
  ORDER_BUMPS_BORDER_KEYS,
} from 'constants/orderBumps.constants'

const { button, header, product, animation, background } = ORDER_BUMPS_TABS_KEYS

const { priceColor } = ORDER_BUMPS_FIELDS_KEYS.product

const { animationBorder, animationArrows, animationHeader, animationButton, animationPrice } =
  ORDER_BUMPS_FIELDS_KEYS.animation

const { buttonButtonColor, buttonViewStyle, buttonRadius } = ORDER_BUMPS_FIELDS_KEYS.button

const { backgroundBorder, backgroundBorderColor } = ORDER_BUMPS_FIELDS_KEYS.background

const { headerColor } = ORDER_BUMPS_FIELDS_KEYS.header

export const getKeyframes = (theme) => {
  const isBorderDisabled = theme[background][backgroundBorder] === ORDER_BUMPS_BORDER_KEYS.no
  const borderColorToUse = isBorderDisabled
    ? theme[button][buttonButtonColor]
    : theme[background][backgroundBorderColor]

  return {
    '@keyframes border-dance-l': {
      '0%': {
        backgroundPosition: '0 0, 100% 100%, 0 100%, 100% 0',
      },
      '100%': {
        backgroundPosition: '100% 0, 0 100%, 0 0, 100% 100%',
      },
    },
    '@keyframes border-dance-r': {
      '0%': {
        backgroundPosition: '100% 0, 0 100%, 0 0, 100% 100%',
      },
      '100%': {
        backgroundPosition: '0 0, 100% 100%, 0 100%, 100% 0',
      },
    },
    '@keyframes moving-arrow-l': {
      '0%': {
        left: 0,
      },
      '50%': {
        left: '10px',
      },
      '100%': {
        left: 0,
      },
    },
    '@keyframes moving-arrow-r': {
      '0%': {
        left: '10px',
      },
      '50%': {
        left: 0,
      },
      '100%': {
        left: '10px',
      },
    },
    '@keyframes swing-button': {
      '15%': {
        '-webkit-transform': 'translateX(5px)',
        transform: 'translateX(5px)',
      },
      '30%': {
        '-webkit-transform': 'translateX(-5px)',
        transform: 'translateX(-5px)',
      },
      '50%': {
        '-webkit-transform': 'translateX(3px)',
        transform: 'translateX(3px)',
      },
      '65%': {
        '-webkit-transform': 'translateX(-3px)',
        transform: 'translateX(-3px)',
      },
      '80%': {
        '-webkit-transform': 'translateX(2px)',
        transform: 'translateX(2px)',
      },
      '100%': {
        '-webkit-transform': 'translateX(0)',
        transform: 'translateX(0)',
      },
    },
    '@keyframes increasing-button': {
      '0%': {
        transform: 'scale(1.010)',
      },
      '80%': {
        transform: 'scale(1.015)',
      },
      '100%': {
        transform: 'scale(1.035)',
      },
    },
    '@keyframes slide-right': {
      from: {
        marginLeft: '-300px',
        opacity: 0,
      },
      to: {
        opacity: 1,
        marginLeft: '0%',
      },
    },
    '@keyframes slide-left': {
      from: {
        marginLeft: '300px',
        opacity: 0,
      },
      to: {
        opacity: 1,
        marginLeft: '0%',
      },
    },
    '@keyframes textclip': {
      to: {
        backgroundPosition: '200% center',
      },
    },
    '@keyframes textclipR': {
      to: {
        backgroundPosition: 'center 200%',
      },
    },
    '@keyframes jump': {
      '0%': {
        transform: 'translateY(0)',
        textShadow: `
              0 0 0 ${theme[button][buttonButtonColor]},
              0 0 0 ${borderColorToUse},
              0 0 0 ${theme[button][buttonButtonColor]},
              0 0 0 ${borderColorToUse};
          `,
      },
      '20%': {
        transform: 'translateY(-0.75em)',
        textShadow: `
              0 0.125em 0 ${theme[button][buttonButtonColor]},
              0 0.25em 0 ${borderColorToUse},
              0 -0.125em 0 ${theme[button][buttonButtonColor]},
              0 -0.25em 0 ${borderColorToUse};
          `,
      },
      '40%': {
        transform: 'translateY(0.5em)',
        textShadow: `
              0 -0.0625em 0 ${theme[button][buttonButtonColor]},
              0 -0.125em 0 ${borderColorToUse},
              0 0.0625em 0 ${theme[button][buttonButtonColor]},
              0 0.125em 0 ${borderColorToUse};
          `,
      },
      '60%': {
        transform: 'translateY(-0.25em)',
        textShadow: `
              0 0.03125em 0 ${theme[button][buttonButtonColor]},
              0 0.0625em 0 ${borderColorToUse},
              0 -0.03125em 0 ${theme[button][buttonButtonColor]},
              0 -0.0625em 0 ${borderColorToUse};
              `,
      },
      '80%': {
        transform: 'translateY(0)',
        textShadow: `
              0 0 0 ${theme[button][buttonButtonColor]},
              0 0 0 ${borderColorToUse},
              0 0 0 ${theme[button][buttonButtonColor]},
              0 0 0 ${borderColorToUse};
          `,
      },
    },
    '@keyframes jump-no-shadow': {
      '0%': {
        transform: 'translateY(0)',
      },
      '20%': {
        transform: 'translateY(-0.75em)',
      },
      '40%': {
        transform: 'translateY(0.5em)',
      },
      '60%': {
        transform: 'translateY(-0.25em)',
      },
      '80%': {
        transform: 'translateY(0)',
      },
    },
    '@keyframes jump-price': {
      '0%': {
        transform: 'translateY(0)',
        textShadow: `
              0 0 0 ${theme[button][buttonButtonColor]},
              0 0 0 ${borderColorToUse},
              0 0 0 ${theme[button][buttonButtonColor]},
              0 0 0 ${borderColorToUse};
          `,
      },
      '20%': {
        transform: 'translateY(-0.3em)',
        textShadow: `
              0 0.125em 0 ${theme[button][buttonButtonColor]},
              0 0.25em 0 ${borderColorToUse},
              0 -0.125em 0 ${theme[button][buttonButtonColor]},
              0 -0.25em 0 ${borderColorToUse};
          `,
      },
      '40%': {
        transform: 'translateY(0.2em)',
        textShadow: `
              0 -0.0625em 0 ${theme[button][buttonButtonColor]},
              0 -0.125em 0 ${borderColorToUse},
              0 0.0625em 0 ${theme[button][buttonButtonColor]},
              0 0.125em 0 ${borderColorToUse};
          `,
      },
      '60%': {
        transform: 'translateY(-0.1em)',
        textShadow: `
              0 0.03125em 0 ${theme[button][buttonButtonColor]},
              0 0.0625em 0 ${borderColorToUse},
              0 -0.03125em 0 ${theme[button][buttonButtonColor]},
              0 -0.0625em 0 ${borderColorToUse};
              `,
      },
      '80%': {
        transform: 'translateY(0)',
        textShadow: `
              0 0 0 ${theme[button][buttonButtonColor]},
              0 0 0 ${borderColorToUse},
              0 0 0 ${theme[button][buttonButtonColor]},
              0 0 0 ${borderColorToUse};
          `,
      },
    },
    '@keyframes jump-no-shadow-price': {
      '0%': {
        transform: 'translateY(0)',
      },
      '20%': {
        transform: 'translateY(-0.3em)',
      },
      '40%': {
        transform: 'translateY(0.2em)',
      },
      '60%': {
        transform: 'translateY(-0.1em)',
      },
      '80%': {
        transform: 'translateY(0)',
      },
    },
  }
}

export const getBorderAnimationKeyframeStyles = (theme) => {
  switch (theme[animation][animationBorder]) {
    case ORDER_BUMPS_BORDER_ANIMATION_KEYS.borderDanceLeft:
      return 'border-dance-l'
    case ORDER_BUMPS_BORDER_ANIMATION_KEYS.borderDanceRight:
      return 'border-dance-r'
    default: {
      return ''
    }
  }
}

export const getHeaderAnimation = (theme) => {
  if (theme.checkboxState) {
    return {}
  }

  switch (theme[animation][animationHeader]) {
    case ORDER_BUMPS_HEADER_ANIMATION_KEYS.shiftLeft: {
      return {
        animation: '3s $slide-left',
      }
    }
    case ORDER_BUMPS_HEADER_ANIMATION_KEYS.shiftRight: {
      return {
        animation: '3s $slide-right',
      }
    }
    case ORDER_BUMPS_HEADER_ANIMATION_KEYS.jump: {
      return {
        animation: '$jump 1.5s ease-in-out infinite',
      }
    }
    case ORDER_BUMPS_HEADER_ANIMATION_KEYS.jumpNoShadow: {
      return {
        animation: '$jump-no-shadow 1.5s ease-in-out infinite',
      }
    }
    case ORDER_BUMPS_HEADER_ANIMATION_KEYS.textClipLeftNoShadow:
    case ORDER_BUMPS_HEADER_ANIMATION_KEYS.textClipRightNoShadow:
    case ORDER_BUMPS_HEADER_ANIMATION_KEYS.textClipLeft:
    case ORDER_BUMPS_HEADER_ANIMATION_KEYS.textClipRight: {
      const isBorderDisabled = theme[background][backgroundBorder] === ORDER_BUMPS_BORDER_KEYS.no
      const borderColorToUse = isBorderDisabled
        ? theme[button][buttonButtonColor]
        : theme[background][backgroundBorderColor]
      const notToRevers = [
        ORDER_BUMPS_HEADER_ANIMATION_KEYS.textClipLeftNoShadow,
        ORDER_BUMPS_HEADER_ANIMATION_KEYS.textClipLeft,
      ]
      const backgroundSettings = [
        ORDER_BUMPS_HEADER_ANIMATION_KEYS.textClipLeftNoShadow,
        ORDER_BUMPS_HEADER_ANIMATION_KEYS.textClipRightNoShadow,
      ].includes(theme[animation][animationHeader])
        ? `linear-gradient(to right, ${COLORS.eloWhite} 10%, ${COLORS.eloWhite} 50%, ${theme[header][headerColor]} 60%)`
        : `linear-gradient(to right, ${theme[button][buttonButtonColor]} 10%, ${borderColorToUse} 50%, ${theme[header][headerColor]} 60%)`

      return {
        animation: `$textclip 1.5s linear infinite${
          notToRevers.includes(theme[animation][animationHeader]) ? '' : ' reverse'
        }`,
        background: backgroundSettings,
        backgroundSize: '200% auto',
        backgroundClip: 'border-box',
        textFillColor: 'transparent',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
      }
    }
    case ORDER_BUMPS_HEADER_ANIMATION_KEYS.no:
    default: {
      return {}
    }
  }
}

export const getPriceAnimation = (theme) => {
  if (theme.checkboxState) {
    return {}
  }

  switch (theme[animation][animationPrice]) {
    case ORDER_BUMPS_HEADER_ANIMATION_KEYS.shiftLeft: {
      return {
        animation: '3s $slide-left',
      }
    }
    case ORDER_BUMPS_HEADER_ANIMATION_KEYS.shiftRight: {
      return {
        animation: '3s $slide-right',
      }
    }
    case ORDER_BUMPS_HEADER_ANIMATION_KEYS.jump: {
      return {
        animation: '$jump-price 1.5s ease-in-out infinite',
      }
    }
    case ORDER_BUMPS_HEADER_ANIMATION_KEYS.jumpNoShadow: {
      return {
        animation: '$jump-no-shadow-price 1.5s ease-in-out infinite',
      }
    }
    case ORDER_BUMPS_HEADER_ANIMATION_KEYS.textClipLeftNoShadow:
    case ORDER_BUMPS_HEADER_ANIMATION_KEYS.textClipRightNoShadow:
    case ORDER_BUMPS_HEADER_ANIMATION_KEYS.textClipLeft:
    case ORDER_BUMPS_HEADER_ANIMATION_KEYS.textClipRight: {
      const isBorderDisabled = theme[background][backgroundBorder] === ORDER_BUMPS_BORDER_KEYS.no
      const borderColorToUse = isBorderDisabled
        ? theme[button][buttonButtonColor]
        : theme[background][backgroundBorderColor]
      const notToRevers = [
        ORDER_BUMPS_HEADER_ANIMATION_KEYS.textClipLeftNoShadow,
        ORDER_BUMPS_HEADER_ANIMATION_KEYS.textClipLeft,
      ]
      const backgroundSettings = [
        ORDER_BUMPS_HEADER_ANIMATION_KEYS.textClipLeftNoShadow,
        ORDER_BUMPS_HEADER_ANIMATION_KEYS.textClipRightNoShadow,
      ].includes(theme[animation][animationPrice])
        ? `linear-gradient(to right, ${COLORS.eloWhite} 10%, ${COLORS.eloWhite} 50%, ${theme[product][priceColor]} 60%)`
        : `linear-gradient(to right, ${theme[button][buttonButtonColor]} 10%, ${borderColorToUse} 50%, ${theme[product][priceColor]} 60%)`

      return {
        animation: `$textclip 1.5s linear infinite ${
          notToRevers.includes(theme[animation][animationPrice]) ? '' : ' reverse'
        }`,
        background: backgroundSettings,
        backgroundSize: '200% auto',
        backgroundClip: 'border-box',
        textFillColor: 'transparent',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
      }
    }
    case ORDER_BUMPS_HEADER_ANIMATION_KEYS.no:
    default: {
      return {}
    }
  }
}

export const getButtonAnimation = (theme) => {
  if (theme.checkboxState) {
    return {}
  }

  switch (theme[animation][animationButton]) {
    case ORDER_BUMPS_BUTTON_ANIMATION_KEYS.shadow: {
      return {
        '&:hover': {
          '-webkit-box-shadow': '0px 8px 15px 0px rgba(0, 0, 0, 0.25)',
          '-moz-box-shadow': '0px 8px 15px 0px rgba(0, 0, 0, 0.25)',
          transition: 'all 0.4s ease 0s',
        },
      }
    }
    case ORDER_BUMPS_BUTTON_ANIMATION_KEYS.transparency: {
      return {
        '&:hover': {
          opacity: 0.8,
        },
      }
    }
    case ORDER_BUMPS_BUTTON_ANIMATION_KEYS.coloredShadow: {
      return {
        '&:hover': {
          '-webkit-box-shadow': `0px 6px 12px 0px ${theme[button][buttonButtonColor]}`,
          '-moz-box-shadow': `0px 6px 12px 0px ${theme[button][buttonButtonColor]}`,
          transition: 'all 0.4s ease 0s',
        },
      }
    }
    case ORDER_BUMPS_BUTTON_ANIMATION_KEYS.stretching: {
      return {
        '&:hover': {
          letterSpacing: 3,
          '-webkit-box-shadow': '0px 5px 16px 0px rgba(0, 0, 0, 0.29)',
          '-moz-box-shadow': '0px 5px 16px 0px rgba(0, 0, 0, 0.29)',
          transition: 'all 0.3s ease 0s',
        },
      }
    }
    case ORDER_BUMPS_BUTTON_ANIMATION_KEYS.bordering: {
      return {
        '&:hover': {
          transition: 'all 0.5s',
          borderRadius: `${theme[button][buttonRadius] > 0 ? 0 : 30}px`,
        },
      }
    }
    case ORDER_BUMPS_BUTTON_ANIMATION_KEYS.increasing: {
      return {
        animationName: '$increasing-button',
        animationDuration: '1s',
        animationTimingFunction: 'ease-in-out',
        transition: '.2s ease-in-out 0s',
        '&:hover': {
          transform: 'scale(1.035)',
        },
      }
    }
    case ORDER_BUMPS_BUTTON_ANIMATION_KEYS.swing: {
      return {
        '&:hover': {
          animation: '$swing-button 1s ease',
          '-webkit-animation-iteration-count': 1,
          'animation-iteration-count': 1,
        },
      }
    }
    case ORDER_BUMPS_BUTTON_ANIMATION_KEYS.fillOutlineSwitch: {
      return {
        transition: 'all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s',
        position: 'relative',
        '&:hover': {
          '& $buttonText': {
            color:
              theme[button][buttonViewStyle] === BUTTON_STYLE.outlined
                ? ''
                : `${theme[button][buttonButtonColor]} !important`,
          },
          '& $leftArrows': {
            color:
              theme[button][buttonViewStyle] === BUTTON_STYLE.outlined
                ? ''
                : `${theme[button][buttonButtonColor]} !important`,
            '& svg': {
              fill:
                theme[button][buttonViewStyle] === BUTTON_STYLE.outlined
                  ? ''
                  : `${theme[button][buttonButtonColor]} !important`,
            },
          },
          '& $rightArrows': {
            color:
              theme[button][buttonViewStyle] === BUTTON_STYLE.outlined
                ? ''
                : `${theme[button][buttonButtonColor]} !important`,
            '& svg': {
              fill:
                theme[button][buttonViewStyle] === BUTTON_STYLE.outlined
                  ? ''
                  : `${theme[button][buttonButtonColor]} !important`,
            },
          },
          background:
            theme[button][buttonViewStyle] === BUTTON_STYLE.outlined
              ? `${theme[button][buttonButtonColor]} !important`
              : 'none !important',
          border:
            theme[button][buttonViewStyle] === BUTTON_STYLE.outlined
              ? 'none'
              : `1px solid ${theme[button][buttonButtonColor]}`,
        },
      }
    }
    case ORDER_BUMPS_BUTTON_ANIMATION_KEYS.no:
    default: {
      return {}
    }
  }
}

export const getCustomFilledButtonAnimation = (theme) => {
  if (
    theme.checkboxState ||
    theme[animation][animationButton] !== ORDER_BUMPS_BUTTON_ANIMATION_KEYS.fillOutlineSwitch
  ) {
    return {}
  }

  return {
    transition: 'all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s',
    position: 'relative',
    '&:hover': {
      '& $leftArrows': {
        color:
          theme[button][buttonViewStyle] === BUTTON_STYLE.outlined
            ? ''
            : `${theme[button][buttonButtonColor]} !important`,
        '& svg': {
          fill:
            theme[button][buttonViewStyle] === BUTTON_STYLE.outlined
              ? ''
              : `${theme[button][buttonButtonColor]} !important`,
        },
      },
      '& $rightArrows': {
        color:
          theme[button][buttonViewStyle] === BUTTON_STYLE.outlined
            ? ''
            : `${theme[button][buttonButtonColor]} !important`,
        '& svg': {
          fill:
            theme[button][buttonViewStyle] === BUTTON_STYLE.outlined
              ? ''
              : `${theme[button][buttonButtonColor]} !important`,
        },
      },
    },
  }
}

export const getArrowAnimation = (theme, type) => {
  const isLeft = type === ORDER_BUMPS_ARROW_TYPES.left

  if (theme[animation][animationArrows] === ORDER_BUMPS_ARROW_ANIMATION_KEYS.no || theme.checkboxState) {
    return {}
  }

  return {
    position: 'relative',
    animation: 'linear infinite',
    animationName: isLeft ? '$moving-arrow-l' : '$moving-arrow-r',
    animationDuration: '1s',
  }
}

export const getBorderStyles = (theme) => {
  const thinBorder = '3px'
  const mediumBorder = '5px'
  const thickBorder = '8px'

  if (theme[animation][animationBorder] === ORDER_BUMPS_BORDER_ANIMATION_KEYS.no || theme.checkboxState) {
    switch (theme[background][backgroundBorder]) {
      case ORDER_BUMPS_BORDER_KEYS.thin:
        return {
          border: `${thinBorder} dashed ${theme[background][backgroundBorderColor]}`,
        }
      case ORDER_BUMPS_BORDER_KEYS.medium:
        return {
          border: `${mediumBorder} dashed ${theme[background][backgroundBorderColor]}`,
        }
      case ORDER_BUMPS_BORDER_KEYS.thick:
        return {
          border: `${thickBorder} dashed ${theme[background][backgroundBorderColor]}`,
        }
      case ORDER_BUMPS_BORDER_KEYS.no:
      default:
        return {}
    }
  } else {
    const animationName = getBorderAnimationKeyframeStyles(theme)
    const animationBase = {
      border: 'none',
      backgroundRepeat: 'repeat-x, repeat-x, repeat-y, repeat-y',
      padding: '10px',
      animationName: `$${animationName}`,
    }

    switch (theme[background][backgroundBorder]) {
      case ORDER_BUMPS_BORDER_KEYS.thin:
        return {
          ...animationBase,
          background: `linear-gradient(90deg, ${theme[background][backgroundBorderColor]} 50%, transparent 50%), linear-gradient(90deg, ${theme[background][backgroundBorderColor]} 50%, transparent 50%), linear-gradient(0deg, ${theme[background][backgroundBorderColor]} 50%, transparent 50%), linear-gradient(0deg, ${theme[background][backgroundBorderColor]} 50%, transparent 50%)`,
          backgroundSize: `15px ${thinBorder}, 15px ${thinBorder}, ${thinBorder} 15px, ${thinBorder} 15px`,
          animation: `${animationName} 25s infinite linear`,
        }
      case ORDER_BUMPS_BORDER_KEYS.medium:
        return {
          ...animationBase,
          background: `linear-gradient(90deg, ${theme[background][backgroundBorderColor]} 60%, transparent 40%), linear-gradient(90deg, ${theme[background][backgroundBorderColor]} 60%, transparent 40%), linear-gradient(0deg, ${theme[background][backgroundBorderColor]} 60%, transparent 40%), linear-gradient(0deg, ${theme[background][backgroundBorderColor]} 60%, transparent 40%)`,
          backgroundSize: `40px ${mediumBorder}, 50px ${mediumBorder}, ${mediumBorder} 40px, ${mediumBorder} 40px`,
          animation: `${animationName} 25s infinite linear`,
        }
      case ORDER_BUMPS_BORDER_KEYS.thick:
        return {
          ...animationBase,
          background: `linear-gradient(90deg, ${theme[background][backgroundBorderColor]} 70%, transparent 30%), linear-gradient(90deg, ${theme[background][backgroundBorderColor]} 70%, transparent 30%), linear-gradient(0deg, ${theme[background][backgroundBorderColor]} 70%, transparent 30%), linear-gradient(0deg, ${theme[background][backgroundBorderColor]} 70%, transparent 30%)`,
          backgroundSize: `70px ${thickBorder}, 70px ${thickBorder}, ${thickBorder} 70px, ${thickBorder} 70px`,
          animation: `${animationName} 20s infinite linear`,
        }
      case ORDER_BUMPS_BORDER_KEYS.no:
      default:
        return {}
    }
  }
}
