import React from 'react'

const SpeedUpArrows = () => (
  <svg
    width='52px'
    height='25px'
    viewBox='0 0 52 25'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g id='Page-1' stroke='none' strokeWidth='1' fillRule='evenodd'>
      <path
        d='M41.4048296,3.55271368e-14 L51.5838422,12.5 L41.4048296,25 L26.4760384,25 L36.655051,12.5 L26.4760384,3.55271368e-14 L41.4048296,3.55271368e-14 Z M22.4371824,3.55271368e-14 L32.6156123,12.5 L22.4371824,25 L17.5638665,25 L27.7428791,12.5 L17.5638665,3.55271368e-14 L22.4371824,3.55271368e-14 Z M13.6275698,3.55271368e-14 L23.805417,12.5 L13.6275698,25 L8.75425388,25 L18.9332665,12.5 L8.75425388,3.55271368e-14 L13.6275698,3.55271368e-14 Z M4.87273321,3.55271368e-14 L15.0511631,12.5 L4.87273321,25 L-1.89182003e-13,25 L10.1784299,12.5 L-1.89182003e-13,3.55271368e-14 L4.87273321,3.55271368e-14 Z'
        id='2'
      />
    </g>
  </svg>
)

export default SpeedUpArrows
