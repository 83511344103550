import React from 'react'

const ClassicAltArrows = () => (
  <svg
    width='48px'
    height='25px'
    viewBox='0 0 48 25'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g id='Page-1' stroke='none' strokeWidth='1' fillRule='evenodd'>
      <polyline
        id='8'
        points='29.1781906 0.067032619 29.0773778 0 29.1993033 9.06946057 0 9.4621556 0.0923677821 16.3248179 29.2916711 15.9315951 29.4135965 25 47.3614483 12.3086667 47.436926 12.2553573 29.1781906 0.067032619'
      />
    </g>
  </svg>
)

export default ClassicAltArrows
